import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import StudentLayout from './StudentLayout';
import { FaFileAlt } from 'react-icons/fa'; // Import a generic file icon
import './UserStudyMaterialList.css';

const UserStudyMaterialList = () => {
    const { id } = useParams(); // Assuming you're getting the course ID from the route params
    const [studyMaterials, setStudyMaterials] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchStudyMaterials = async () => {
            try {
                const cachedStudyMaterials = localStorage.getItem(`study_materials_${id}`);
                if (cachedStudyMaterials) {
                    setStudyMaterials(JSON.parse(cachedStudyMaterials));
                } else {
                    const accessToken = localStorage.getItem('accessToken');
                    if (accessToken) {
                        const response = await axios.get(`https://www.testometer.in:8080/api/v1/user/courses/${id}/study-material`, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                        setStudyMaterials(response.data);
                        localStorage.setItem(`study_materials_${id}`, JSON.stringify(response.data));
                    } else {
                        setError('Access token not found');
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching study materials:', error.message);
                setError(error.message);
            }
        };
    
        fetchStudyMaterials();
    }, [id]);
    

      if (loading) {
        return (
            <div className="user-loader">
                <div className="user-spinner"></div>
            </div>
        );
    }

    return (
        <StudentLayout>
            <div className='student-main-content'>
                {error && <p className="error-message">Error: {error}</p>}
                <div className="user-study-material-list">
                    <h2 className='title'>Study Materials</h2>
                    <div className="material-grid">
                        {studyMaterials.map((material) => (
                            <div key={material.id} className="user-study-material-box">
                                <a href={material.url} target="_blank" rel="noopener noreferrer">
                                    <div className="icon-container"><FaFileAlt /></div>
                                    
                                </a>
                                <p className="material-title">{material.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </StudentLayout>
    );
};

export default UserStudyMaterialList;


