import React from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
// import AuthService from '../Service/AuthService';

const Sidebar = () => {
    // const handleSignOut = () => {
    //     AuthService.signOut();
    //     navigate('/sign-in');
    //   };
  return (
    <div className='admin-dashboard'>
        <div className='sidebar'>
            <div className='sidebar-header'>
                <h2 style={{color: '#023075'}}>Admin Dashboard</h2>
            </div>
            <ul>
                <li><Link to={`/admin`}>Dashboard</Link></li>
                <li><Link to={`/total-subscribed-users`}>Subscribed Users</Link></li>
                <li><Link to={`/admin-course-list`}>Courses</Link></li>
                <li><Link to='/sign-in'>Sign Out</Link></li>
                {/* Add more sidebar items as needed */}
            </ul>
        </div>
    </div>
  );
};

export default Sidebar;