import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const StudyMaterialViewer = () => {
    const { materialId } = useParams(); // Get the material ID from the route params
    const [fileData, setFileData] = useState(null);
    const [contentType, setContentType] = useState('');

    useEffect(() => {
        const fetchStudyMaterial = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    console.error('Access token not found');
                    return;
                }

                const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/study-material/${materialId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    responseType: 'arraybuffer', // Ensure the response is treated as a binary file
                });

                setContentType(response.headers['content-type']);
                setFileData(response.data);
            } catch (error) {
                console.error('Error fetching study material:', error.message);
            }
        };

        fetchStudyMaterial();
    }, [materialId]);

    const renderFile = () => {
        if (fileData && contentType) {
            const blob = new Blob([fileData], { type: contentType });
            const objectURL = URL.createObjectURL(blob);

            // Adjust iframe styles for a smaller viewer
            const iframeStyle = {
                width: '80vw', // Adjust width as needed
                height: '80vh', // Adjust height as needed
                border: 'none', // Remove border if desired
                pointerEvents: 'auto', // Allow interactions
                overflow: 'auto', // Enable scrolling if content overflows
                transform: 'scale(0.9)', // Default zoom level, adjust as needed
                transformOrigin: '0 0', // Zoom from the top-left corner
            };

            if (contentType.includes('pdf')) {
                return (
                    <iframe
                        src={objectURL}
                        title="PDF Viewer"
                        style={iframeStyle}
                    />
                );
            } else if (contentType.includes('msword') || contentType.includes('wordprocessingml.document')) {
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(objectURL)}`}
                        title="Word Viewer"
                        style={iframeStyle}
                    />
                );
            } else if (contentType.includes('vnd.ms-powerpoint') || contentType.includes('presentationml.presentation')) {
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(objectURL)}`}
                        title="PowerPoint Viewer"
                        style={iframeStyle}
                    />
                );
            } else {
                return <p>Unsupported file format.</p>;
            }
        }
        return <p>Loading...</p>;
    };

    return (
        <AdminLayout>
            <div className="study-material-viewer-container" style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%', 
                height: '100%', 
                position: 'relative'
            }}>
                {renderFile()}
            </div>
        </AdminLayout>
    );
};

export default StudyMaterialViewer;















