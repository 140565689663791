

// AuthService.js
import axios from 'axios';

const API_URL = 'https://www.testometer.in:8080/api/v1/auth';

const AuthService = {
  signUp: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/signup`, userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
  signIn: async (credentials) => {
    try {
      const response = await axios.post(`${API_URL}/signin`, credentials);

      if (response && response.data) {
        const { token, refreshToken, user } = response.data;
        const userId = user.id;

        // Store tokens and user data in localStorage
        localStorage.setItem('userId', userId);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));

        return { token, refreshToken, user };
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      throw error;
    }
  },

  signOut: () => {
    // Clear tokens and user data from localStorage on sign out
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
  },

  refreshToken: async (refreshToken) => {
    try {
      const response = await axios.post(`${API_URL}/refresh`, { refreshToken });
      const { token, user } = response.data;
      const userId = user.id;

      // Update the access token and user data in localStorage
      localStorage.setItem('userId', userId);
      localStorage.setItem('accessToken', token);
      localStorage.setItem('user', JSON.stringify(user));

      return token;
    } catch (error) {
      console.error('Error refreshing token', error)
      throw error;
    }
  },

  isAuthenticated: () => {
    // Check if access token is present in localStorage
    return localStorage.getItem('accessToken') !== null;
  },

  getUserRole: () => {
    // Get user role from localStorage
    const userData = JSON.parse(localStorage.getItem('user'));
    return userData ? userData.role : null;
  }
};

export default AuthService;

