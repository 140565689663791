// import React, { useState } from 'react';
// import { Link, useLocation, NavLink } from 'react-router-dom';
// import logo from './logo.png';

// const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const [customMessage, setCustomMessage] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [menuOpen, setMenuOpen] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch(`https://www.testometer.in:8080/api/v1/auth/forgot-password?email=${email}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       if (response.ok) {
//         setCustomMessage('Reset password link sent successfully to your email.');
//         setShowModal(true);
//       } else {
//         setCustomMessage('An error occurred. Please try again later.');
//         setShowModal(true);
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       setCustomMessage('An error occurred. Please try again later.');
//       setShowModal(true);
//     }
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <>

//       <nav className="navbar">
//         <div className="logo">
//           <Link to="/">
//             <img src={logo} alt='Company Logo' />
//           </Link>
//         </div>
//         <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
//           <span></span>
//           <span></span>
//           <span></span>
//         </div>

//         <ul className={menuOpen ? "open" : ""}>
//           <li><NavLink to="/sign-up">Sign Up</NavLink></li>
//           <li><NavLink to="/sign-in">Log In</NavLink></li>
//         </ul>
//       </nav>

//     <div className='signin-container'>
//         <div className='form-header'>
//           <h3>Forgot Password</h3>
          
//         </div>
//         <p>Please enter your registered email ID</p>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="email"
//           placeholder="Enter your email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <button className='sign-up-button' type="submit">Submit</button>
//       </form>
//       {showModal && (
//         <div className="password-modal">
//           <div className="password-modal-content">
//             <p>{customMessage}</p>
//           </div>
//         </div>
//       )}
//     </div>
//     </>
//   );
// };

// export default ForgotPassword;





import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from './logo.png';
import Footer from './Footer';
 

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await fetch(`https://www.testometer.in:8080/api/v1/auth/forgot-password?email=${email}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          setCustomMessage('Reset password link sent successfully to your email.');
        } else {
          setCustomMessage('An error occurred. Please try again later.');
        }
      } catch (error) {
        console.error('Error:', error);
        setCustomMessage('An error occurred. Please try again later.');
      }
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>

      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt='Company Logo' />
          </Link>
        </div>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={menuOpen ? "open" : ""}>
          <li><NavLink to="/sign-up">Sign Up</NavLink></li>
          <li><NavLink to="/sign-in">Log In</NavLink></li>
        </ul>
      </nav>

    <div className='signin-container'>
        <div className='form-header'>
          <h3>Forgot Password</h3>
          
        </div>
        <p>Please enter your registered email ID</p>
      <form onSubmit={handleSubmit}>
        <div className='signin-input-group'>
        <label>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="error">{errors.email}</div>
        </label>
        </div>
        <button className='sign-up-button' type="submit">Submit</button>
      </form>
      {showModal && (
        <div className="password-modal">
          <div className="password-modal-content">
            <p>{customMessage}</p>
          </div>
        </div>
      )}
    </div>

    <Footer />
    </>
  );
};

export default ForgotPassword;