

import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import './HomePage.css';
import logo from './logo.png';
import Footer from './Footer';

const HomePage = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const courses = [
    { id: 1, title: 'Selenium Web-Driver with Python', description: 'Welcome to our Selenium WebDriver with Python course! This course is designed to teach you how to automate web applications using Selenium WebDriver and Python', videoLink: 'https://www.youtube.com/embed/qr5JWkIk4vY?si=eRuXvh3ssLf4cmxQ' },
    { id: 2, title: 'Selenium Web-Driver with Java', description: 'Selenium WebDriver with Java! This course is designed to provide you with the skills and knowledge necessary to become an expert in automated testing using Selenium WebDriver and Java.', videoLink: 'https://www.youtube.com/embed/IT4raMxJX44?si=4PbxjP0tFatpTfr8' },
    { id: 3, title: 'Performance Testing Using JMeter', description: 'JMeter is a popular open-source tool for performance testing, load testing, and stress testing of web applications. This JMeter Performance Testing Training course is designed to help participants learn the skills needed to create and execute effective performance tests using JMeter.', videoLink: 'https://www.youtube.com/embed/ysPo1iKf328?si=yxvly8Vcn-zdwV8L' },
    { id: 4, title: 'API Automation Testing', description: 'Welcome to our API Testing course using Postman and Rest Assured! This course is designed to provide you with the skills and knowledge necessary to become an expert in API testing using these two powerful tools.', videoLink: 'https://www.youtube.com/embed/Z9ULOP5iTFE?si=SBugBqfkBpjJnDju' },
    { id: 5, title: 'ISTQB Training', description: 'Boost your career with our comprehensive ISTQB Core- Foundation & Advanced Level Training and Certification program! Discover the path to success as we guide you through all ISTQB exam levels, equipping you with the essential skills and knowledge to excel in software testing. Enroll now and unlock endless opportunities in the dynamic world of quality assurance.', videoLink: 'https://www.youtube.com/embed/7M3zyYVPyCk?si=v84efG-okgfgABSV' },
  ];

  return (
    <div className="homepage-container">
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Company Logo" />
          </Link>
        </div>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
  
        <ul className={menuOpen ? "open" : ""}>
          <li><NavLink to="/sign-up">Sign Up</NavLink></li>
          <li><NavLink to="/sign-in">Log In</NavLink></li>
        </ul>
      </nav>
      
      <div className="courses-section">
        <h2 className="title">Featured Courses with Demo Sessions</h2>
        
        {courses.map((course, index) => (
          <div key={course.id} className={`course-card ${index % 2 === 0 ? 'right-video' : 'left-video'}`}>
            <div className="course-details">
              <h3>{course.title}</h3>
              <p>{course.description}</p>
            </div>
            <div className="video-container">
              <iframe
                title={course.title}
                src={course.videoLink}
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;











