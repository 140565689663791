import React from 'react';
import { NavLink } from "react-router-dom";
import './MobileNavbar.css';

const MobileNavbar = ({ menuItem }) => {
  return (
    <div className="mobile-navbar">
      <div className="navbar-content">
        {menuItem.map((item, index) => (
          item.path === '/profile' ? (
            <div key={index} className="link" onClick={item.onClick}>
              {item.name}
            </div>
          ) : (
            <NavLink to={item.path} key={index} className="link" activeClassName="active">
              {item.name}
            </NavLink>
          )
        ))}
      </div>
    </div>
  );
};

export default MobileNavbar;




