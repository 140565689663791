// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams, Link } from 'react-router-dom';
// import AdminLayout from './AdminLayout';
// import { FiFile, FiTrash } from 'react-icons/fi'; // Import the FiFile and FiTrash icons from react-icons

// const StudyMaterialList = () => {
//     const { id } = useParams(); // Assuming you're getting the course ID from the route params
//     const [studyMaterials, setStudyMaterials] = useState([]);
//     const [loading, setLoading] = useState(true);
    
//     useEffect(() => {
//         const fetchStudyMaterials = async () => {
//           try {
//             const accessToken = localStorage.getItem('accessToken');
//             if (!accessToken) {
//               console.error('Access token not found');
//               return;
//             }
            
//             const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/study-material`, {
//               headers: {
//                 Authorization: `Bearer ${accessToken}`,
//               },
//             });
            
//             setStudyMaterials(response.data);
//             setLoading(false);
//           } catch (error) {
//             console.error('Error fetching study materials data:', error.message);
//             // Handle error (display error message, etc.)
//           }
//         };
      
//         fetchStudyMaterials();
//       }, [id]);
      
      

//     const handleDelete = async (studyMaterialId) => {
//         try {
//             const accessToken = localStorage.getItem('accessToken');

//             if (accessToken) {
//                 await axios.delete(`https://www.testometer.in:8080/api/v1/admin/study-material/${studyMaterialId}`, {
//                     headers: {
//                         Authorization: `Bearer ${accessToken}`,
//                     },
//                 });
//                 setStudyMaterials(studyMaterials.filter(material => material.id !== studyMaterialId));
//                 console.log('Study material removed successfully!');
//             } else {
//                 console.error('Access token not found');
//             }
//         } catch (error) {
//             console.error('Error removing study material:', error.message);
//             // Handle error (display error message, etc.)
//         }
//     };

//     if (loading) {
//         return (
//             <div className="user-loader">
//                 <div className="user-spinner"></div>
//             </div>
//         );
//     }

//     return (
//         <>
//             <AdminLayout>
//                 <div className='main-content'>
//                     <div className="study-material-list-container">
//                     <Link className='btn btn-dark' to={`/add-study-material/${id}`}>Add Study Material</Link><br></br>
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                     <th>View</th>
//                                     <th>Title</th>
//                                     <th>Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {studyMaterials.map((material) => (
//                                     <tr key={material.id}>
//                                         <td><a href={material.url} target="_blank" rel="noopener noreferrer"><FiFile /></a></td>
//                                         <td>{material.title}</td>
//                                         <td>
                                            
//                                             <button onClick={() => handleDelete(material.id)}><FiTrash /></button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
                        
//                     </div>
//                 </div>
//             </AdminLayout>
//         </>
//     );
// };

// export default StudyMaterialList;












import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import { FiFile, FiTrash } from 'react-icons/fi'; // Import icons

const StudyMaterialList = () => {
    const { id } = useParams(); // Assuming you're getting the course ID from the route params
    const [studyMaterials, setStudyMaterials] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStudyMaterials = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    console.error('Access token not found');
                    return;
                }

                const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/study-material`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setStudyMaterials(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching study materials data:', error.message);
                // Handle error (display error message, etc.)
            }
        };

        fetchStudyMaterials();
    }, [id]);

    const handleDelete = async (studyMaterialId) => {
        try {
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken) {
                await axios.delete(`https://www.testometer.in:8080/api/v1/admin/study-material/${studyMaterialId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setStudyMaterials(studyMaterials.filter(material => material.id !== studyMaterialId));
                console.log('Study material removed successfully!');
            } else {
                console.error('Access token not found');
            }
        } catch (error) {
            console.error('Error removing study material:', error.message);
            // Handle error (display error message, etc.)
        }
    };

    if (loading) {
        return (
            <div className="user-loader">
                <div className="user-spinner"></div>
            </div>
        );
    }

    return (
        <>
            <AdminLayout>
                <div className='main-content'>
                    <div className="study-material-list-container">
                        <Link className='btn btn-dark' to={`/add-study-material/${id}`}>Add Study Material</Link><br />
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>View</th>
                                    <th>Title</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studyMaterials.map((material) => (
                                    <tr key={material.id}>
                                        <td>
                                            <Link to={`/study-material/${material.id}/view`}>
                                                <FiFile />
                                            </Link>
                                        </td>
                                        <td>{material.title}</td>
                                        <td>
                                            <button onClick={() => handleDelete(material.id)}><FiTrash /></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default StudyMaterialList;









