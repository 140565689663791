// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import AdminLayout from './AdminLayout';

// const SubscriptionRequests = () => {
//   const { id } = useParams();
//   const [subscriptionRequests, setSubscriptionRequests] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const fetchSubscriptionRequests = async () => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/subscription-requests`, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       // Check if response.data is an array
//       if (Array.isArray(response.data)) {
//         setSubscriptionRequests(response.data);
//         setLoading(false);
//       } else {
//         console.error('Error: Response data is not an array');
//       }
//     } catch (error) {
//       console.error('Error fetching subscription requests:', error.message);
//     }
//   };

//   useEffect(() => {
//     fetchSubscriptionRequests();
//   }, [id]);

//   const handleApprove = async (userId) => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       await axios.post(`https://www.testometer.in:8080/api/v1/admin/approve/${id}/${userId}?approve=true`, null, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       setLoading(false);
//       fetchSubscriptionRequests();
//     } catch (error) {
//       console.error('Error approving request:', error.message);
//     }
//   };

//   const handleReject = async (userId) => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       await axios.post(`https://www.testometer.in:8080/api/v1/admin/approve/${id}/${userId}?approve=false`, null, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       setLoading(false);
//       fetchSubscriptionRequests();
//     } catch (error) {
//       console.error('Error rejecting request:', error.message);
//     }
//   };

//   if (loading) {
//     return (
//         <div className="user-loader">
//             <div className="user-spinner"></div>
//         </div>
//     );
// }

//   return (
//     <AdminLayout>
//       <div className='main-content'>
//         <div className="subscription-requests-page">
//           <h2>Subscription Requests for Course: {id}</h2>
//           <table className='table table-bordered'>
//             <thead>
//               <tr>
//                 <th>Student ID</th>
//                 <th>First Name</th>
//                 <th>Last Name</th>
//                 <th>Email</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {subscriptionRequests.map((request) => (
//                 <tr key={request.id}>
//                   <td>{request.user.student_id}</td>
//                   <td>{request.user.first_name}</td>
//                   <td>{request.user.last_name}</td>
//                   <td>{request.user.email}</td>
//                   <td>
//                     <button className='btn btn-dark' onClick={() => handleApprove(request.user.id)}>Approve</button>
//                     <button className='btn btn-danger' onClick={() => handleReject(request.user.id)} style={{marginLeft:'10px'}}>Reject</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </AdminLayout>
//   );
// };

// export default SubscriptionRequests;








// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import AdminLayout from './AdminLayout';

// const SubscriptionRequests = () => {
//   const { id } = useParams();
//   const [subscriptionRequests, setSubscriptionRequests] = useState([]);
//   const [loading, setLoading] = useState(true);
  
//   const fetchSubscriptionRequests = async () => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/subscription-requests`, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       // Check if response.data is an array
//       if (Array.isArray(response.data)) {
//         setSubscriptionRequests(response.data);
//         setLoading(false);
//       } else {
//         console.error('Error: Response data is not an array');
//       }
//     } catch (error) {
//       console.error('Error fetching subscription requests:', error.message);
//     }
//   };

//   useEffect(() => {
//     fetchSubscriptionRequests();
//   }, [id]);

//   const handleApprove = async (userId) => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       await axios.post(`https://www.testometer.in:8080/api/v1/admin/approve/${id}/${userId}?approve=true`, null, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       setLoading(true); // Set loading to true before fetching subscription requests
//       fetchSubscriptionRequests();
//     } catch (error) {
//       console.error('Error approving request:', error.message);
//     }
//   };

//   const handleReject = async (userId) => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       await axios.post(`https://www.testometer.in:8080/api/v1/admin/approve/${id}/${userId}?approve=false`, null, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       setLoading(true); // Set loading to true before fetching subscription requests
//       fetchSubscriptionRequests();
//     } catch (error) {
//       console.error('Error rejecting request:', error.message);
//     }
//   };

//   if (loading) {
//     return (
//         <div className="user-loader">
//             <div className="user-spinner"></div>
//         </div>
//     );
//   }

//   return (
//     <AdminLayout>
//       <div className='main-content'>
//         <div className="subscription-requests-page">
//           <h2>Subscription Requests for Course: {id}</h2>
//           <table className='table table-bordered'>
//             <thead>
//               <tr>
//                 <th>Student ID</th>
//                 <th>User ID</th>
//                 <th> Name</th>
//                 <th>Email</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {subscriptionRequests.map((request) => (
//                 <tr key={request.id}>
//                   <td>{request.user.student_id}</td>
//                   <td>{request.user.id}</td>
//                   <td>{request.user.first_name} {request.user.last_name}</td>
//                   <td>{request.user.email}</td>
//                   <td>
//                     <button className='btn btn-dark' onClick={() => handleApprove(request.user.id)}>Approve</button>
//                     <button className='btn btn-danger' onClick={() => handleReject(request.user.id)} style={{marginLeft:'10px'}}>Reject</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </AdminLayout>
//   );
// };

// export default SubscriptionRequests;








import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const SubscriptionRequests = () => {
  const { id } = useParams();
  const [subscriptionRequests, setSubscriptionRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const fetchSubscriptionRequests = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/subscription-requests`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Check if response.data is an array
      if (Array.isArray(response.data)) {
        setSubscriptionRequests(response.data);
        setLoading(false);
      } else {
        console.error('Error: Response data is not an array');
      }
    } catch (error) {
      console.error('Error fetching subscription requests:', error.message);
    }
  };

  useEffect(() => {
    fetchSubscriptionRequests();
  }, [id]);

  const handleApprove = async (userId) => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        await axios.post(`https://www.testometer.in:8080/api/v1/admin/approve/${id}/${userId}?approve=true`, null, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        // Remove the approved request from the list
        setSubscriptionRequests(subscriptionRequests.filter(request => request.user.id !== userId));
    } catch (error) {
        console.error('Error approving request:', error.message);
    }
};

const handleReject = async (userId) => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        await axios.post(`https://www.testometer.in:8080/api/v1/admin/approve/${id}/${userId}?approve=false`, null, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        // Remove the rejected request from the list
        setSubscriptionRequests(subscriptionRequests.filter(request => request.user.id !== userId));
    } catch (error) {
        console.error('Error rejecting request:', error.message);
    }
};
  if (loading) {
    return (
        <div className="user-loader">
            <div className="user-spinner"></div>
        </div>
    );
  }

  return (
    <AdminLayout>
      <div className='main-content'>
        <div className="subscription-requests-page">
          <h2>Subscription Requests for Course: {id}</h2>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>User ID</th>
                <th> Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscriptionRequests.map((request) => (
                <tr key={request.id}>
                  <td>{request.user.student_id}</td>
                  <td>{request.user.id}</td>
                  <td>{request.user.first_name} {request.user.last_name}</td>
                  <td>{request.user.email}</td>
                  <td>
                    <div className='btn-group'>
                      <button type='button' className='btn btn-dark dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'></button>
                        <ul className='dropdown-menu'>
                          <button className='dropdown-item' onClick={() => handleApprove(request.user.id)}>Approve</button>
                          <button className='dropdown-item' onClick={() => handleReject(request.user.id)}>Reject</button>
                        </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default SubscriptionRequests;