

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import StudentLayout from './StudentLayout';
import './UserCourseDetails.css'; 
import Notification from './Notification';

const UserCourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState('');
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchCourseDetails = async () => {
        try {
            const cachedCourseDetails = localStorage.getItem(`course_details_${id}`);
            if (cachedCourseDetails) {
                setCourseDetails(JSON.parse(cachedCourseDetails));
            } else {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    const response = await axios.get(`https://www.testometer.in:8080/api/v1/user/courses/${id}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setCourseDetails(response.data);
                    localStorage.setItem(`course_details_${id}`, JSON.stringify(response.data));
                } else {
                    console.error('Access token not found');
                }
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching course details:', error.message);
        }
    };

    fetchCourseDetails();
}, [id]);



  const handleSubscribe = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        await axios.post(`https://www.testometer.in:8080/api/v1/user/courses/${id}/subscribe`, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setNotificationMessage('Subscription request sent for approval');
        setTimeout(() => {
          navigate('/student-dash');
        }, 3000); 
      } else {
        console.error('Access token not found');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setNotificationMessage('You are already subscribed.');
        setTimeout(() => {
          navigate('/course-list');
        }, 2000); 
      } else {
        console.error('Error subscribing to course:', error.message);
      }
    }
  };

  if (loading) {
    return (
        <div className="user-loader">
            <div className="user-spinner"></div>
        </div>
    );
}

  return (
    <StudentLayout>
      <div className='student-main-content'>
        <div className="course-details-container">
          <div className="course-details-content">
            <h2>{courseDetails.course_title}</h2>
            <p>{courseDetails.description}</p>
            <button onClick={handleSubscribe}>Subscribe</button>
            {notificationMessage && (
              <Notification message={notificationMessage} onClose={() => setNotificationMessage('')} />
            )}
          </div>
        </div>
      </div>
    </StudentLayout>
  );
};

export default UserCourseDetails;
