

// import React, { useState } from 'react';
// import './SignUpForm.css';
// import { useNavigate, Link, NavLink } from 'react-router-dom';
// import AuthService from '../Service/AuthService';
// import logo from './logo.png';

// const SignUpForm = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     first_name: '',
//     last_name: '',
//     email: '',
//     student_id: '',
//     password: '',
//     confirm_password: '', 
//   });

//   const [errors, setErrors] = useState({
//     first_name: '',
//     last_name: '',
//     email: '',
//     student_id: '',
//     password: '',
//     confirm_password: '', 
//   });

//   const [showModal, setShowModal] = useState(false);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     setErrors({ ...errors, [e.target.name]: '' });
//   };

//   const validateForm = () => {
//     let isValid = true;
//     const newErrors = { ...errors };

//     if (formData.first_name.trim() === '') {
//       newErrors.first_name = 'First name is required';
//       isValid = false;
//     }

//     if (formData.student_id.trim() === '') {
//       newErrors.student_id = 'Student ID is required';
//       isValid = false;
//     }

//     if (formData.last_name.trim() === '') {
//       newErrors.last_name = 'Last name is required';
//       isValid = false;
//     }

//     if (formData.email.trim() === '') {
//       newErrors.email = 'Email is required';
//       isValid = false;
//     } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
//       newErrors.email = 'Invalid email address';
//       isValid = false;
//     }

//     if (formData.password.trim() === '') {
//       newErrors.password = 'Password is required';
//       isValid = false;
//     }

//     if (formData.password !== formData.confirm_password) {
//       newErrors.confirm_password = 'Passwords do not match';
//       isValid = false;
//     }

//     setErrors(newErrors);
//     return isValid;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       try {
//         const response = await AuthService.signUp(formData);
//         console.log('Signup successful:', response.data);
//         setShowModal(true);
//       } catch (error) {
//         console.error('Signup error:', error.message);
//         if (error.response && error.response.status === 409) {
//           alert('You are already registered. Please sign in.');
//           navigate('/sign-in');
//         } else {
//           // Handle other errors
//         }
//       }
//     } else {
//       console.log('Form validation failed. Please check your input.');
//     }
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     navigate('/sign-in');
//   };

//   return (
//     <>
//       <nav className="navbar">
//         <div className="logo">
//           <Link to="/">
//             <img src={logo} alt='Company Logo' />
//           </Link>
//         </div>
//         <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
//           <span></span>
//           <span></span>
//           <span></span>
//         </div>

//         <ul className={menuOpen ? "open" : ""}>
//           <li><NavLink to="/sign-up">Sign Up</NavLink></li>
//           <li><NavLink to="/sign-in">Log In</NavLink></li>
//         </ul>
//       </nav>
//       <div className="signup-container">
//         <div className="form-header">
//           <h3>Sign Up Form</h3>
//         </div>
//         <form onSubmit={handleSubmit}>
//           <div className="input-group">
//             <label>
//               <input type="text" name="first_name" placeholder="First Name*" value={formData.first_name} onChange={handleChange} />
//               <div className="error">{errors.first_name}</div>
//             </label>
//             <label>
//               <input type="text" name="last_name" placeholder="Last Name*" value={formData.last_name} onChange={handleChange} />
//               <div className="error">{errors.last_name}</div>
//             </label>
//           </div><br></br>
//           <div className="input-group">
//             <label>
//               <input type="email" name="email" placeholder="Email ID*" value={formData.email} onChange={handleChange} />
//               <div className="error">{errors.email}</div>
//             </label>
//             <label>
//               <input type="text" name="student_id" placeholder="Student ID*" value={formData.student_id} onChange={handleChange} />
//               <div className="error">{errors.student_id}</div>
//             </label>
//           </div><br></br>
//           <div className="input-group">
//             <label>
//               <input type="password" name="password" placeholder="Password*" value={formData.password} onChange={handleChange} />
//               <div className="error">{errors.password}</div>
//             </label>
//             <label>
//               <input type="password" name="confirm_password" placeholder="Confirm Password*" value={formData.confirm_password} onChange={handleChange} />
//               <div className="error">{errors.confirm_password}</div>
//             </label>
//           </div>
//           <button type="submit" className="sign-up-button">Sign Up</button>
//         </form>
//         <div className="link-container">
//           <Link to="/sign-in">Click here to sign in</Link>
//         </div>
//       </div>

//       {showModal && (
//         <div className="sign-up-modal">
//           <div className="sign-up-modal-content">
//             <h4>Registration Successful</h4>
//             <p>You have successfully registered. Please sign in to continue.</p>
//             <button onClick={closeModal}>Close</button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default SignUpForm;





import React, { useState } from 'react';
import './SignUpForm.css';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import AuthService from '../Service/AuthService';
import logo from './logo.png';
import Footer from './Footer';

const SignUpForm = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    student_id: '',
    password: '',
    confirm_password: '', 
  });

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    student_id: '',
    password: '',
    confirm_password: '', 
  });

  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (formData.first_name.trim() === '') {
      newErrors.first_name = 'First name is required';
      isValid = false;
    }

    if (formData.student_id.trim() === '') {
      newErrors.student_id = 'Student ID is required';
      isValid = false;
    }

    if (formData.last_name.trim() === '') {
      newErrors.last_name = 'Last name is required';
      isValid = false;
    }

    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
      isValid = false;
    }

    if (formData.password.trim() === '') {
      newErrors.password = 'Password is required';
      isValid = false;
    }

    if (formData.password !== formData.confirm_password) {
      newErrors.confirm_password = 'Passwords do not match';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await AuthService.signUp(formData);
        console.log('Signup successful:', response.data);
        setShowModal(true);
      } catch (error) {
        console.error('Signup error:', error.message);
        if (error.response && error.response.status === 409) {
          alert('You are already registered. Please sign in.');
          navigate('/sign-in');
        } else {
          // Handle other errors
        }
      }
    } else {
      console.log('Form validation failed. Please check your input.');
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate('/sign-in');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="homepage-container">
        <header>
          <nav className="navbar">
            <div className="logo">
              <Link to="/">
                    <img src={logo} alt='Company Logo' />
              </Link>
            </div>
            <div className={`menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className={menuOpen ? 'open' : ''}>
              <li>
                <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home</NavLink>
              </li>
              
              <li>
              <li><NavLink to="/sign-in">Log In</NavLink></li>
              </li>
              
            </ul>
          </nav>
        </header>
      </div>
      
      <div className="signup-container">
        <div className="form-header">
          <h3>Sign Up</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>
              <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} placeholder="First Name" />
              {errors.first_name && <span className="error">{errors.first_name}</span>}
            </label>
            <label>
              <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} placeholder="Last Name" />
              {errors.last_name && <span className="error">{errors.last_name}</span>}
            </label>
            <label className="full-width">
              <input type="text" name="student_id" value={formData.student_id} onChange={handleChange} placeholder="Student ID" />
              {errors.student_id && <span className="error">{errors.student_id}</span>}
            </label>
            <label className="full-width">
              <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email ID" />
              {errors.email && <span className="error">{errors.email}</span>}
            </label>
            <label>
              <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" />
              {errors.password && <span className="error">{errors.password}</span>}
            </label>
            <label>
              <input type="password" name="confirm_password" value={formData.confirm_password} onChange={handleChange} placeholder="Confirm Password" />
              {errors.confirm_password && <span className="error">{errors.confirm_password}</span>}
            </label>
          </div>
          <button type="submit" className="sign-up-button">Sign Up</button>
        </form>
        <div className="link-container">
          <p>
            Already have an account? <Link to="/sign-in">Sign in here</Link>.
          </p>
        </div>
      </div>

      {showModal && (
        <div className="sign-up-modal">
          <div className="sign-up-modal-content">
            <h4>Registration Successful!</h4>
            <p>Welcome to the platform!</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default SignUpForm;





