// import React, { useState } from 'react';
// import { Link, useLocation, NavLink } from 'react-router-dom';
// import logo from './logo.png';

// const ResetPassword = () => {
//   const location = useLocation();
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   const [menuOpen, setMenuOpen] = useState(false);
  

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const token = new URLSearchParams(location.search).get('token');
//     try {
//       const response = await fetch(`https://www.testometer.in:8080/api/v1/auth/reset-password?token=${token}&newPassword=${password}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       if (response.ok) {
//         setSuccessMessage('Password has been reset successfully.');
//         setErrorMessage('');
//       } else {
//         setErrorMessage('Password reset failed. Please try again.');
//         setSuccessMessage('');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       setErrorMessage('An error occurred. Please try again later.');
//       setSuccessMessage('');
//     }
//   };


//   return (
//     <>

//       <nav className="navbar">
//         <div className="logo">
//           <Link to="/">
//             <img src={logo} alt='Company Logo' />
//           </Link>
//         </div>
//         <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
//           <span></span>
//           <span></span>
//           <span></span>
//         </div>

//         <ul className={menuOpen ? "open" : ""}>
//           <li><NavLink to="/sign-up">Sign Up</NavLink></li>
//           <li><NavLink to="/sign-in">Log In</NavLink></li>
//         </ul>
//       </nav>

//     <div className='signin-container'>
//         <div className='form-header'>
//         <h3>Reset Password</h3>
//         </div>
      
//       <form onSubmit={handleSubmit}>
//         <input
//           style={{ marginBottom: '20px' }}
//           type="password"
//           placeholder="Enter new password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <br></br>
//         <input
//           style={{ marginBottom: '20px' }}
//           type="password"
//           placeholder="Confirm new password"
//           value={confirmPassword}
//           onChange={(e) => setConfirmPassword(e.target.value)}
//         />
//         <button className='sign-up-button' type="submit">Reset Password</button>
//       </form>
//       {(successMessage || errorMessage) && (
//         <div className="password-modal">
//           <div className="password-modal-content">
//             {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
//             {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
//             <div className="link-container">
//                 <Link to="/sign-in">Click here to sign in</Link>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//     </>
//   );
// };

// export default ResetPassword;




import React, { useState } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import logo from './logo.png';
import Footer from './Footer';


const ResetPassword = () => {
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (password.trim() === '') {
      newErrors.password = 'Password is required';
    }

    if (confirmPassword.trim() === '') {
      newErrors.confirmPassword = 'Confirm Password is required';
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const token = new URLSearchParams(location.search).get('token');
    try {
      const response = await fetch(`https://www.testometer.in:8080/api/v1/auth/reset-password?token=${token}&newPassword=${password}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        setSuccessMessage('Password has been reset successfully.');
        setErrorMessage('');
      } else {
        setErrorMessage('Password reset failed. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again later.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt='Company Logo' />
          </Link>
        </div>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={menuOpen ? "open" : ""}>
          <li><NavLink to="/sign-up">Sign Up</NavLink></li>
          <li><NavLink to="/sign-in">Log In</NavLink></li>
        </ul>
      </nav>

      <div className='signin-container'>
        <div className='form-header'>
          <h3>Reset Password</h3>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='signin-input-group'>
          <label>
            <input
              style={{ marginBottom: '20px' }}
              type="password"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="error">{errors.password}</div>
          </label>
          <label>
            <input
              style={{ marginBottom: '20px' }}
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="error">{errors.confirmPassword}</div>
          </label>
          </div>
          <button className='sign-up-button' type="submit">Reset Password</button>
        </form>

        {(successMessage || errorMessage) && (
          <div className="password-modal">
            <div className="password-modal-content">
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              <div className="link-container">
                <Link to="/sign-in">Click here to sign in</Link>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default ResetPassword;

