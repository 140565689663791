// import React, { useEffect, useState } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import axios from 'axios';
// import './LiveClassList.css'; // Import the CSS file
// import AdminLayout from './AdminLayout';

// const LiveClassList = () => {
//   const { id } = useParams();
//   const [liveClasses, setLiveClasses] = useState([]);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchLiveClasses = async () => {
//       try {
//         const accessToken = localStorage.getItem('accessToken');
//         if (accessToken) {
//           const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/live-class`, {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           });
//           console.log('Live class list response:', response.data);
//           setLiveClasses(response.data);
//         } else {
//           setError('Access token not found');
//         }
//       } catch (error) {
//         console.error('Error fetching live classes:', error.message);
//         setError(error.message);
//       }
//     };

//     fetchLiveClasses();
//   }, [id]);

//   const handleRemoveLiveClass = async (liveClassId) => {
//     try {
//       const confirmation = window.confirm('Are you sure you want to remove this live class?');
//       if (confirmation) {
//         const accessToken = localStorage.getItem('accessToken');
//         if (accessToken) {
//           await axios.delete(`https://www.testometer.in:8080/api/v1/admin/live-class/${liveClassId}`, {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           });
//           setLiveClasses(liveClasses.filter(liveClass => liveClass.id !== liveClassId));
//           console.log('Live class removed successfully!');
//         } else {
//           console.error('Access token not found');
//         }
//       }
//     } catch (error) {
//       console.error('Error removing live class:', error.message);
//       // Handle error (display error message, etc.)
//     }
//   };
  

//   return (
//     <>
//     <AdminLayout>
//     <div className='main-content'>
//       <h3>Live Classes for Course ID: {id}</h3><br></br>

//       <Link className='btn btn-dark' to={`/add-live-class/${id}`}>Add Live Class</Link><br></br><br></br><br></br>

//       {error && <p>Error: {error}</p>}
//       <div className="live-class-container">
//         {liveClasses.map((liveClass) => (
//           <div key={liveClass.id} className="live-class-box">
//             <h4>{liveClass.class_title}</h4>
//             <p>{liveClass.meeting_link}</p>
//             <p>{liveClass.description}</p>
//             <p>Start Date: {liveClass.start_date}</p>
//             <p>Start Time: {liveClass.start_time}</p><br></br>
            
//             <Link className='btn btn-dark' to={`/live-class-attendance/${liveClass.id}`}>Get Attendance</Link>
//             <Link className='btn btn-dark' to={`/add-live-class/${id}/${liveClass.id}`}>Edit</Link>
//             <button className='btn btn-danger' onClick={() => handleRemoveLiveClass(liveClass.id)} style={{ marginLeft: '20px' }}>Remove</button>
//           </div>
//         ))}
//       </div>
//     </div>
//     </AdminLayout>
//     </>
//   );
// };

// export default LiveClassList;




import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './LiveClassList.css'; // Import the CSS file
import AdminLayout from './AdminLayout';

const LiveClassList = () => {
  const { id } = useParams();
  const [liveClasses, setLiveClasses] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLiveClasses = async () => {
      try {
        let liveClassesData = JSON.parse(localStorage.getItem('liveClassesData'));
        if (!liveClassesData) {
          const accessToken = localStorage.getItem('accessToken');
          if (!accessToken) {
            console.error('Access token not found');
            return;
          }
          
          const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/live-class`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          
          liveClassesData = response.data;
          localStorage.setItem('liveClassesData', JSON.stringify(liveClassesData));
        }
        
        setLiveClasses(liveClassesData);
      } catch (error) {
        console.error('Error fetching or storing live classes data:', error.message);
        // Handle error (display error message, etc.)
      }
    };
  
    fetchLiveClasses();
  }, [id]);
  

  const handleRemoveLiveClass = async (liveClassId) => {
    try {
      const confirmation = window.confirm('Are you sure you want to remove this live class?');
      if (confirmation) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          await axios.delete(`https://www.testometer.in:8080/api/v1/admin/live-class/${liveClassId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setLiveClasses(liveClasses.filter(liveClass => liveClass.id !== liveClassId));
          console.log('Live class removed successfully!');
        } else {
          console.error('Access token not found');
        }
      }
    } catch (error) {
      console.error('Error removing live class:', error.message);
      // Handle error (display error message, etc.)
    }
  };
  

  return (
    <>
    <AdminLayout>
    <div className='main-content'>
      <h3>Live Classes for Course ID: {id}</h3><br></br>

      <Link className='btn btn-dark' to={`/add-live-class/${id}`}>Add Live Class</Link><br></br><br></br><br></br>

      {error && <p>Error: {error}</p>}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Class Title</th>
            <th>Meeting Link</th>
            <th>Description</th>
            <th>Start Date</th>
            <th>Start Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {liveClasses.map((liveClass) => (
            <tr key={liveClass.id}>
              <td>{liveClass.class_title}</td>
              <td>{liveClass.meeting_link}</td>
              <td>{liveClass.description}</td>
              <td>{liveClass.start_date}</td>
              <td>{liveClass.start_time}</td>
              <td>
                <div className='btn group'>
                  <button type='button' className='btn btn-dark dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'> </button>
                  <ul className='dropdown-menu'>
                    <Link className='dropdown-item' to={`/live-class-attendance/${liveClass.id}`}>Get Attendance</Link>
                    <Link className='dropdown-item' to={`/add-live-class/${id}/${liveClass.id}`}>Edit</Link>
                    <button className='dropdown-item' onClick={() => handleRemoveLiveClass(liveClass.id)}>Remove</button>
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </AdminLayout>
    </>
  );
};

export default LiveClassList;
