// import React, { useState } from 'react';
// import axios from 'axios';
// import { useParams, useNavigate, Link } from 'react-router-dom';
// import AdminLayout from './AdminLayout';

// const AddStudyMaterialToCourse = () => {
//     const { id } = useParams(); // Assuming you're getting the course ID from the route params
//     const navigate = useNavigate();
//     const [formData, setFormData] = useState({
//         title: '',
//         url: ''
//     });

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const accessToken = localStorage.getItem('accessToken');

//             if (accessToken) {
//                 await axios.post(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/study-material`, formData, {
//                     headers: {
//                         Authorization: `Bearer ${accessToken}`,
//                     },
//                 });
//                 console.log('Study material added successfully!');
//                 navigate(`/course-details/${id}`);
//             } else {
//                 console.error('Access token not found');
//             }
//         } catch (error) {
//             console.error('Error adding study material:', error.message);
//             // Handle error (display error message, etc.)
//         }
//     };

//     return (
//         <>
//             <AdminLayout>
//                 <div className='main-content'>
//                     <div className="add-study-material-container">
//                         <h2>Add Study Material to Course</h2>
//                         <form onSubmit={handleSubmit}>
//                             <div className='mb-3'>
//                                 <label htmlFor='title' className='form-label'>Title:</label>
//                                 <input type="text" className='form-control' id="title" name="title" value={formData.title} onChange={handleChange} />
//                             </div>

//                             <div className='mb-3'>
//                                 <label htmlFor='url' className='form-label'>File URL:</label>
//                                 <input type="text" className='form-control' id="url" name="url" value={formData.url} onChange={handleChange} />
//                             </div>

//                             <button className='btn btn-dark' type="submit">Add Study Material</button>
//                             <Link className='btn btn-danger' to={`/course-details/${id}`} style={{ marginLeft: '10px' }}>Cancel</Link>
//                         </form>
//                     </div>
//                 </div>
//             </AdminLayout>
//         </>
//     );
// };

// export default AddStudyMaterialToCourse;








import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const AddStudyMaterialToCourse = () => {
    const { id } = useParams(); // Course ID from the route params
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        file: null, // File is stored as a blob
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(); // FormData is used for sending files
        data.append('title', formData.title);
        data.append('file', formData.file); // File is appended to the form data

        try {
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken) {
                await axios.post(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/study-material`, data, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'multipart/form-data', // Important for file uploads
                    },
                });
                console.log('Study material added successfully!');
                navigate(`/course-details/${id}`);
            } else {
                console.error('Access token not found');
            }
        } catch (error) {
            console.error('Error adding study material:', error.message);
            // Handle error (display error message, etc.)
        }
    };

    return (
        <>
            <AdminLayout>
                <div className='main-content'>
                    <div className="add-study-material-container">
                        <h2>Add Study Material to Course</h2>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className='mb-3'>
                                <label htmlFor='title' className='form-label'>Title:</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor='file' className='form-label'>File:</label>
                                <input
                                    type="file"
                                    className='form-control'
                                    id="file"
                                    name="file"
                                    onChange={handleFileChange}
                                    accept=".pdf,.doc,.docx,.ppt,.pptx" // Limit file types
                                />
                            </div>

                            <button className='btn btn-dark' type="submit">Add Study Material</button>
                            <Link className='btn btn-danger' to={`/course-details/${id}`} style={{ marginLeft: '10px' }}>Cancel</Link>
                        </form>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default AddStudyMaterialToCourse;


