// StudentLayout.js
import React, { useState } from "react";
import StudentSidebar from "./StudentSidebar";
import "./StudentLayout.css"; // Import your CSS file for StudentLayout

const StudentLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`student-layout ${isOpen ? "sidebar-open" : ""}`}>
      <StudentSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="content-wrapper">
        <div className="student-main-content">{children}</div>
      </div>
    </div>
  );
};

export default StudentLayout;





