

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import StudentLayout from './StudentLayout';
import './UserLiveClass.css'; // Import the CSS file for styling

const UserLiveClass = () => {
  const { id } = useParams();
  const [liveClasses, setLiveClasses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLiveClasses = async () => {
        try {
            const cachedLiveClasses = localStorage.getItem(`live_classes_${id}`);
            if (cachedLiveClasses) {
                setLiveClasses(JSON.parse(cachedLiveClasses));
            } else {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    const response = await axios.get(`https://www.testometer.in:8080/api/v1/user/courses/${id}/live-class`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setLiveClasses(response.data);
                    localStorage.setItem(`live_classes_${id}`, JSON.stringify(response.data));
                } else {
                    setError('Access token not found');
                }
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching live classes:', error.message);
            setError(error.message);
        }
    };

    fetchLiveClasses();
}, [id]);


  const isJoinEnabled = (startTime) => {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const currentTime = new Date();
    
    const currentUTCHour = currentTime.getUTCHours();
    const currentUTCMinute = currentTime.getUTCMinutes();

    const currentHour = (currentUTCHour + 5) % 24; 
    const currentMinute = currentUTCMinute + 30;

    
  
    // Convert start time to milliseconds since midnight
    const startTimeMs = startHour * 3600000 + startMinute * 60000;
    // Convert current time to milliseconds since midnight
    const currentTimeMs = currentHour * 3600000 + currentMinute * 60000;
  
    // Calculate the time after 30 minutes from the start time
    const thirtyMinutesLaterMs = startTimeMs + 90 * 60000;
  
    // Enable the button if the current time is at the class start time or after
    // and before 30 minutes from the start time
    return currentTimeMs >= startTimeMs && currentTimeMs <= thirtyMinutesLaterMs;
  };
  

  const handleJoinLiveClass = async (liveClass) => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId');
        const liveClassId = liveClass.id;

        if (accessToken && userId && liveClassId) {
            // Mark that the user has joined this live class
            localStorage.setItem(`joined_${liveClassId}_${userId}`, 'true');

            // Capture the current time in Indian Standard Time (IST)
            const currentTimeIST = new Date();
            const formattedTime = `${currentTimeIST.getFullYear()}-${(currentTimeIST.getMonth() + 1).toString().padStart(2, '0')}-${currentTimeIST.getDate().toString().padStart(2, '0')} ${currentTimeIST.getHours().toString().padStart(2, '0')}:${currentTimeIST.getMinutes().toString().padStart(2, '0')}:${currentTimeIST.getSeconds().toString().padStart(2, '0')}`;
            
            // Log the formatted time in IST
            console.log('Formatted time in IST:', formattedTime); 
            
            // Send a request to mark attendance
            await axios.post(
                `https://www.testometer.in:8080/api/v1/user/courses/${id}/live-class/${liveClassId}/join`,
                {
                    timestamp: formattedTime // Include the captured time in the request body
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            // Open the meeting link in a new tab
            window.open(liveClass.meeting_link, '_blank');
        } else {
            setError('Access token, user ID, or live class ID not found');
        }
    } catch (error) {
        console.error('Error joining live class:', error.message);
        setError('Error joining live class');
    }
};

if (loading) {
  return (
      <div className="user-loader">
          <div className="user-spinner"></div>
      </div>
  );
}


  return (
    <StudentLayout>
      <div className='student-main-content'>
        {/* <h3>Live Classes for Course ID: {id}</h3><br /> */}
        
        {error && <p className="error-message">Error: {error}</p>}
        <div className="live-class-list">
          {liveClasses.map((liveClass) => (
            <div key={liveClass.id} className="live-class-item">
              <h4>{liveClass.class_title}</h4>
              {/* <p>{liveClass.meeting_link}</p> */}
              <p>{liveClass.description}</p>
              <p>Start Date: {liveClass.start_date}</p>
              <p>Start Time: {liveClass.start_time}</p>
              <br></br>
              <button 
                onClick={() => handleJoinLiveClass(liveClass)}
                disabled={!isJoinEnabled(liveClass.start_time)}
                className={isJoinEnabled(liveClass.start_time) ? 'join-button' : 'disabled-button'}
              >
                {isJoinEnabled(liveClass.start_time) ? 'Join Live Class' : 'Live Class Not Available Yet'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </StudentLayout>
  );
};

export default UserLiveClass;























