


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link, useParams } from 'react-router-dom';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
// import './UserCourseAccess.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
// import StudentLayout from './StudentLayout';
// import { FaFileAlt } from 'react-icons/fa';

// const UserCourseAccess = () => {
//     const [courseDetails, setCourseDetails] = useState(null);
//     const [videoUrl, setVideoUrl] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [loading, setLoading] = useState(true);
//     const { id } = useParams();

//     useEffect(() => {
//         const fetchCourseDetails = async () => {
//             try {
//                 const accessToken = localStorage.getItem('accessToken');
//                 if (accessToken) {
//                     const response = await axios.get(`https://www.testometer.in:8080/api/v1/user/courses/${id}`, {
//                         headers: {
//                             Authorization: `Bearer ${accessToken}`,
//                         },
//                     });
//                     setCourseDetails(response.data);
//                 } else {
//                     console.error('Access token not found');
//                 }
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching course details:', error.message);
//             }
//         };

//         fetchCourseDetails();
//     }, [id]);

//     const handleVideoClick = (videoUrl) => {
//         const fileId = videoUrl.match(/\/file\/d\/([^/]+)\//)[1];
//         const embedUrl = `https://drive.google.com/file/d/${fileId}/preview?rm=minimal`;
//         setVideoUrl(embedUrl);
//         setShowModal(true);
//     };

//     const filteredVideos = () => {
//         let filteredVideos = courseDetails?.videos || [];
//         if (searchQuery) {
//             filteredVideos = filteredVideos.filter(video =>
//                 video.video_title.toLowerCase().includes(searchQuery.toLowerCase())
//             );
//         }
//         filteredVideos.sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
//         return filteredVideos;
//     };

//     if (loading) {
//         return (
//             <div className="user-loader">
//                 <div className="user-spinner"></div>
//             </div>
//         );
//     }

//     return (
//         <StudentLayout>
//             <div className='student-container'>
//                 <div className='student-main-content'>
//                     <h1 className='title'>{courseDetails?.course_title}</h1>
//                     <div className='videos-box-container'>
//                       <div className='videos-box'>
//                     <div className="course-content">
//                         <h2 style={{color:'#023075', textAlign:'center', fontWeight:'bolder'}}>Course content</h2>
//                         <Tabs>
//                             <TabList>
//                                 <Tab style={{color:'#023075'}}>Recorded sessions</Tab>
//                                 <Tab style={{color:'#023075'}}>Study materials</Tab>
//                                 <Tab style={{color:'#023075'}}>Live classes</Tab>
//                             </TabList>

//                             <TabPanel>
//                               <br></br>
//                                 <input
//                                     type="text"
//                                     placeholder="Search video"
//                                     value={searchQuery}
//                                     onChange={(e) => setSearchQuery(e.target.value)}
//                                     className="search-input"
//                                 />
//                                 <br></br><br></br>

//                                 <div className="videos-grid">
//                                     {filteredVideos().map((video) => (
//                                         <div key={video.id} className="video-card">
//                                             <h3 style={{ color: '#023075', fontFamily: 'Arial, sans-serif' }}>{video.video_title}</h3>
//                                             <p>{video.video_description}</p>
//                                             <div className="play-icon" onClick={() => handleVideoClick(video.video_url)}>
//                                                 <FontAwesomeIcon icon={faPlayCircle} size="3x" />
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </TabPanel>
//                             <TabPanel>
//                                 {/* <div className="study-material-list">
//                                     {courseDetails?.studyMaterials.map((material) => (
//                                         <div key={material.id} className="material-card">
//                                             <h3 style={{ color: '#333', fontFamily: 'Arial, sans-serif' }}>{material.title}</h3>
//                                             <p>{material.description}</p>
//                                             <Link to={`/user-study-material/${material.url}`}>Access Material</Link>
//                                         </div>
//                                     ))}
//                                 </div> */}

//                                  <div className="user-study-material-list">
//                                      <div className="material-grid">
//                                          {courseDetails?.studyMaterials.map((material) => (
//                                              <div key={material.id} className="user-study-material-box">
//                                                  <a href={material.url} target="_blank" rel="noopener noreferrer">
//                                                      <div className="icon-container"><FaFileAlt /></div>

//                                                  </a>
//                                                  <p className="material-title">{material.title}</p>
//                                              </div>
//                                          ))}
//                                      </div>
//                                  </div>

//                             </TabPanel>
//                             <TabPanel>
//                                 <div className="live-class-list">
//                                     {courseDetails?.liveClasses.map((liveClass) => (
//                                         <div key={liveClass.id} className="live-class-card">
//                                             <h3 style={{ color: '#333', fontFamily: 'Arial, sans-serif' }}>{liveClass.title}</h3>
//                                             <p>{liveClass.description}</p>
//                                             <Link to={`/user-live-class/${liveClass.id}`}>Join Class</Link>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </TabPanel>
//                         </Tabs>
//                     </div>

//                     {showModal && (
//                         <div className="modal" style={{ display: showModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 9999, left: 0, top: 0, width: '100%', height: '100%', overflow: 'auto' }}>
//                             <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '15% auto', padding: '20px', border: '1px solid #888', width: '100%' }}>
//                                 <span className="close" onClick={() => setShowModal(false)} style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer' }}>&times;</span>
//                                 <div className="video-player" style={{ position: 'relative', zIndex: 2 }}>
//                                     <iframe
//                                         title="Google Drive Video Player"
//                                         src={videoUrl}
//                                         width="100%"
//                                         height="480vh"
//                                         frameBorder="0"
//                                         allowFullScreen
//                                         sandbox="allow-same-origin allow-scripts"
//                                     ></iframe>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     </div>
//                     </div>
//                 </div>
//             </div>
//         </StudentLayout>
//     );
// };

// export default UserCourseAccess;









// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
// import './UserCourseAccess.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
// import StudentLayout from './StudentLayout';
// import { FaFileAlt } from 'react-icons/fa';

// const UserCourseAccess = () => {
//     const [courseDetails, setCourseDetails] = useState(null);
//     const [videoUrl, setVideoUrl] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [loading, setLoading] = useState(true);
//     const { id } = useParams();

//     useEffect(() => {
//         const fetchCourseDetails = async () => {
//             try {
//                 const accessToken = localStorage.getItem('accessToken');
//                 if (accessToken) {
//                     const response = await axios.get(`https://www.testometer.in:8080/api/v1/user/courses/${id}`, {
//                         headers: {
//                             Authorization: `Bearer ${accessToken}`,
//                         },
//                     });
//                     setCourseDetails(response.data);
//                 } else {
//                     console.error('Access token not found');
//                 }
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching course details:', error.message);
//             }
//         };

//         fetchCourseDetails();
//     }, [id]);

//     const handleVideoClick = (videoUrl) => {
//         const fileId = videoUrl.match(/\/file\/d\/([^/]+)\//)[1];
//         const embedUrl = `https://drive.google.com/file/d/${fileId}/preview?rm=minimal`;
//         setVideoUrl(embedUrl);
//         setShowModal(true);
//     };

//     const filteredVideos = () => {
//         let filteredVideos = courseDetails?.videos || [];
//         if (searchQuery) {
//             filteredVideos = filteredVideos.filter(video =>
//                 video.video_title.toLowerCase().includes(searchQuery.toLowerCase())
//             );
//         }
//         filteredVideos.sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
//         return filteredVideos;
//     };

//     const getMostRecentVideo = () => {
//         const sortedVideos = courseDetails?.videos?.slice().sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
//         return sortedVideos ? sortedVideos[0] : null;
//     };

//     const mostRecentVideo = getMostRecentVideo();

//     if (loading) {
//         return (
//             <div className="user-loader">
//                 <div className="user-spinner"></div>
//             </div>
//         );
//     }

//     return (
//         <StudentLayout>
//             <div className='student-container'>
//                 <div className='student-main-content'>
//                     {/* <h1 className='title'>{courseDetails?.course_title}</h1> */}
                    
//                       <div className='videos-box'>
//                       <h3 className='title'>{courseDetails?.course_title}</h3><br></br>
//                     <div className="course-content">
//                         {/* <h2 style={{color:'#023075', textAlign:'center', fontWeight:'bolder'}}>Course content</h2> */}
//                         <Tabs>
//                             <TabList>
//                                 <Tab >Recorded sessions</Tab>
//                                 <Tab >Study materials</Tab>
                                
//                             </TabList>

//                             <TabPanel>
//                               <br></br>
//                                 <input
//                                     type="text"
//                                     placeholder="Search video"
//                                     value={searchQuery}
//                                     onChange={(e) => setSearchQuery(e.target.value)}
//                                     className="search-input"
//                                 />
                               

//                                 <div className="videos-grid">
//                                             {filteredVideos().length > 0 ? (
//                                                 filteredVideos().map((video) => (
//                                                     <div key={video.id} className={`video-card ${video.id === mostRecentVideo?.id ? 'highlight' : ''}`}>
//                                                         {video.id === mostRecentVideo?.id && <span className="new-video-label">New Video</span>}
//                                                         <h3 style={{ color: '#023075', fontFamily: 'Arial, sans-serif' }}>{video.video_title}</h3>
//                                                         <p>{video.video_description}</p>
//                                                         <div className="play-icon" onClick={() => handleVideoClick(video.video_url)}>
//                                                             <FontAwesomeIcon icon={faPlayCircle} size="3x" />
//                                                         </div>
//                                                     </div>
//                                                 ))
//                                             ) : (
//                                                 <h2 style={{color:'#ff6f61', textAlign:'center', fontWeight:'bolder'}}>No content to display...</h2>
//                                             )}
//                                         </div>
//                             </TabPanel>
//                             <TabPanel>
                                

//                             <div className="user-study-material-list">
//                                     <div className="material-grid">
//                                              {courseDetails?.studyMaterials.length > 0 ? (
//                                                  courseDetails?.studyMaterials.map((material) => (
//                                                      <div key={material.id} className="user-study-material-box">
//                                                          <a href={material.url} target="_blank" rel="noopener noreferrer">
//                                                              <div className="icon-container"><FaFileAlt /></div>
//                                                          </a>
//                                                          <p className="material-title">{material.title}</p>
//                                                      </div>
//                                                  ))
//                                              ) : (
//                                                  <h2 style={{color:'#ff6f61', textAlign:'center', fontWeight:'bolder'}}>No content to display...</h2>
//                                              )}
//                                     </div>
//                                 </div>

//                             </TabPanel>
                            
//                         </Tabs>
                    

//                     {showModal && (
//                         <div className="modal" style={{ display: showModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 9999, left: 0, top: 0, width: '100%', height: '100%', overflow: 'auto' }}>
//                             <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '15% auto', padding: '20px', border: '1px solid #888', width: '100%' }}>
//                                 <span className="close" onClick={() => setShowModal(false)} style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer' }}>&times;</span>
//                                 <div className="video-player" style={{ position: 'relative', zIndex: 2 }}>
//                                     <iframe
//                                         title="Google Drive Video Player"
//                                         src={videoUrl}
//                                         width="100%"
//                                         height="480vh"
//                                         frameBorder="0"
//                                         allowFullScreen
//                                         sandbox="allow-same-origin allow-scripts"
//                                     ></iframe>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     </div>
//                     </div>
//                 </div>
//             </div>
//         </StudentLayout>
//     );
// };

// export default UserCourseAccess;








// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
// import './UserCourseAccess.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
// import StudentLayout from './StudentLayout';
// import { FaFileAlt } from 'react-icons/fa';

// const UserCourseAccess = () => {
//     const [courseDetails, setCourseDetails] = useState(null);
//     const [videoUrl, setVideoUrl] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [videoSearchQuery, setVideoSearchQuery] = useState('');
//     const [materialSearchQuery, setMaterialSearchQuery] = useState('');
//     const [loading, setLoading] = useState(true);
//     const { id } = useParams();

//     useEffect(() => {
//         const fetchCourseDetails = async () => {
//             try {
//                 const accessToken = localStorage.getItem('accessToken');
//                 if (accessToken) {
//                     const response = await axios.get(`https://www.testometer.in:8080/api/v1/user/courses/${id}`, {
//                         headers: {
//                             Authorization: `Bearer ${accessToken}`,
//                         },
//                     });
//                     setCourseDetails(response.data);
//                 } else {
//                     console.error('Access token not found');
//                 }
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching course details:', error.message);
//             }
//         };

//         fetchCourseDetails();
//     }, [id]);

//     const handleVideoClick = (videoUrl) => {
//         const fileId = videoUrl.match(/\/file\/d\/([^/]+)\//)[1];
//         const embedUrl = `https://drive.google.com/file/d/${fileId}/preview?rm=minimal`;
//         setVideoUrl(embedUrl);
//         setShowModal(true);
//     };

//     const filteredVideos = () => {
//         let filteredVideos = courseDetails?.videos || [];
//         if (videoSearchQuery) {
//             filteredVideos = filteredVideos.filter(video =>
//                 video.video_title.toLowerCase().includes(videoSearchQuery.toLowerCase())
//             );
//         }
//         filteredVideos.sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
//         return filteredVideos;
//     };

//     const filteredStudyMaterials = () => {
//         let filteredMaterials = courseDetails?.studyMaterials || [];
//         if (materialSearchQuery) {
//             filteredMaterials = filteredMaterials.filter(material =>
//                 material.title.toLowerCase().includes(materialSearchQuery.toLowerCase())
//             );
//         }
//         filteredMaterials.sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
//         return filteredMaterials;
//     };

//     const getMostRecentVideo = () => {
//         const sortedVideos = courseDetails?.videos?.slice().sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
//         return sortedVideos ? sortedVideos[0] : null;
//     };

//     const mostRecentVideo = getMostRecentVideo();

//     const formatDate = (dateString) => {
//         const options = { year: 'numeric', month: 'long', day: 'numeric' };
//         return new Date(dateString).toLocaleDateString(undefined, options);
//     };

//     if (loading) {
//         return (
//             <div className="user-loader">
//                 <div className="user-spinner"></div>
//             </div>
//         );
//     }

//     return (
//         <StudentLayout>
//             <div className='student-container'>
//                 <div className='student-main-content'>
//                     <div className='videos-box'>
//                         <h3 className='title'>{courseDetails?.course_title}</h3><br></br>
//                         <div className="course-content">
//                             <Tabs>
//                                 <TabList>
//                                     <Tab>Recorded sessions</Tab>
//                                     <Tab>Study materials</Tab>
//                                 </TabList>

//                                 <TabPanel>
//                                     <br></br>
//                                     <input
//                                         type="text"
//                                         placeholder="Search video"
//                                         value={videoSearchQuery}
//                                         onChange={(e) => setVideoSearchQuery(e.target.value)}
//                                         className="search-input"
//                                     />
//                                     <div className="videos-grid">
//                                         {filteredVideos().length > 0 ? (
//                                             filteredVideos().map((video) => (
//                                                 <div key={video.id} className={`video-card ${video.id === mostRecentVideo?.id ? 'highlight' : ''}`}>
//                                                     {video.id === mostRecentVideo?.id && <span className="new-video-label">New Video</span>}
//                                                     <h3 style={{ color: '#023075', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>{video.video_title}</h3>
//                                                     <span style={{ color: '#023075', fontFamily: 'Arial, sans-serif'}}>Upload date: {formatDate(video.created_date_time)}</span>
//                                                     <p>{video.video_description}</p>
//                                                     <div className="play-icon" onClick={() => handleVideoClick(video.video_url)}>
//                                                         <FontAwesomeIcon icon={faPlayCircle} size="3x" />
//                                                     </div>
//                                                 </div>
//                                             ))
//                                         ) : (
//                                             <h2 style={{color:'#ff6f61', textAlign:'center', fontWeight:'bolder'}}>No content to display...</h2>
//                                         )}
//                                     </div>
//                                 </TabPanel>
//                                 <TabPanel>
//                                     <br></br>
//                                     <input
//                                         type="text"
//                                         placeholder="Search material"
//                                         value={materialSearchQuery}
//                                         onChange={(e) => setMaterialSearchQuery(e.target.value)}
//                                         className="search-input"
//                                     />
//                                     <div className="user-study-material-list">
//                                         <div className="material-grid">
//                                             {filteredStudyMaterials().length > 0 ? (
//                                                 filteredStudyMaterials().map((material) => (
//                                                     <div key={material.id} className="user-study-material-box">
//                                                         <a href={material.url} target="_blank" rel="noopener noreferrer">
//                                                             <div className="icon-container"><FaFileAlt /></div>
//                                                         </a>
//                                                         <p className="material-title">{material.title}</p>
//                                                     </div>
//                                                 ))
//                                             ) : (
//                                                 <h2 style={{color:'#ff6f61', textAlign:'center', fontWeight:'bolder'}}>No content to display...</h2>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </TabPanel>
//                             </Tabs>
//                             {showModal && (
//                                 <div className="modal">
//                                     <div className="modal-content">
//                                         <span className="close" onClick={() => setShowModal(false)}>&times;</span>
//                                         <div className="modal-header">
//                                             <h1 className="modal-title">{mostRecentVideo?.video_title}</h1>
//                                         </div>
//                                         <div className="video-player">
//                                             <iframe
//                                                 title="Google Drive Video Player"
//                                                 src={videoUrl}
//                                                 width="100%"
//                                                 height="480"
//                                                 frameBorder="0"
//                                                 allowFullScreen
//                                                 sandbox="allow-same-origin allow-scripts"
//                                             ></iframe>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )}

//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </StudentLayout>
//     );
// };

// export default UserCourseAccess;











import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './UserCourseAccess.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import StudentLayout from './StudentLayout';
import { FaFileAlt } from 'react-icons/fa';

const UserCourseAccess = () => {
    const [courseDetails, setCourseDetails] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [videoSearchQuery, setVideoSearchQuery] = useState('');
    const [materialSearchQuery, setMaterialSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentVideoTitle, setCurrentVideoTitle] = useState(''); // New state for the current video title
    const { id } = useParams();

    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    const response = await axios.get(`https://www.testometer.in:8080/api/v1/user/courses/${id}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setCourseDetails(response.data);
                } else {
                    console.error('Access token not found');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching course details:', error.message);
            }
        };

        fetchCourseDetails();
    }, [id]);

    const handleVideoClick = (videoUrl, videoTitle) => {
        const fileId = videoUrl.match(/\/file\/d\/([^/]+)\//)[1];
        const embedUrl = `https://drive.google.com/file/d/${fileId}/preview?rm=minimal`;
        setVideoUrl(embedUrl);
        setCurrentVideoTitle(videoTitle); // Set the current video title
        setShowModal(true);
    };

    const filteredVideos = () => {
        let filteredVideos = courseDetails?.videos || [];
        if (videoSearchQuery) {
            filteredVideos = filteredVideos.filter(video =>
                video.video_title.toLowerCase().includes(videoSearchQuery.toLowerCase())
            );
        }
        filteredVideos.sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
        return filteredVideos;
    };

    const filteredStudyMaterials = () => {
        let filteredMaterials = courseDetails?.studyMaterials || [];
        if (materialSearchQuery) {
            filteredMaterials = filteredMaterials.filter(material =>
                material.title.toLowerCase().includes(materialSearchQuery.toLowerCase())
            );
        }
        filteredMaterials.sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
        return filteredMaterials;
    };

    const getMostRecentVideo = () => {
        const sortedVideos = courseDetails?.videos?.slice().sort((a, b) => new Date(b.created_date_time) - new Date(a.created_date_time));
        return sortedVideos ? sortedVideos[0] : null;
    };

    const mostRecentVideo = getMostRecentVideo();

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    if (loading) {
        return (
            <div className="user-loader">
                <div className="user-spinner"></div>
            </div>
        );
    }

    return (
        <StudentLayout>
            <div className='student-container'>
                <div className='student-main-content'>
                    <div className='videos-box'>
                        <h3 className='title'>{courseDetails?.course_title}</h3><br></br>
                        <div className="course-content">
                            <Tabs>
                                <TabList>
                                    <Tab>Recorded sessions</Tab>
                                    <Tab>Study materials</Tab>
                                </TabList>

                                <TabPanel>
                                    <br></br>
                                    <input
                                        type="text"
                                        placeholder="Search video"
                                        value={videoSearchQuery}
                                        onChange={(e) => setVideoSearchQuery(e.target.value)}
                                        className="search-input"
                                    />
                                    <div className="videos-grid">
                                        {filteredVideos().length > 0 ? (
                                            filteredVideos().map((video) => (
                                                <div key={video.id} className={`video-card ${video.id === mostRecentVideo?.id ? 'highlight' : ''}`}>
                                                    {video.id === mostRecentVideo?.id && <span className="new-video-label">New Video</span>}
                                                    <h3 style={{ color: '#023075', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>{video.video_title}</h3>
                                                    <span style={{ color: '#023075', fontFamily: 'Arial, sans-serif'}}>Upload date: {formatDate(video.created_date_time)}</span>
                                                    <p>{video.video_description}</p>
                                                    <div className="play-icon" onClick={() => handleVideoClick(video.video_url, video.video_title)}>
                                                        <FontAwesomeIcon icon={faPlayCircle} size="3x" />
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <h2 style={{color:'#ff6f61', textAlign:'center', fontWeight:'bolder'}}>No content to display...</h2>
                                        )}
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <br></br>
                                    <input
                                        type="text"
                                        placeholder="Search material"
                                        value={materialSearchQuery}
                                        onChange={(e) => setMaterialSearchQuery(e.target.value)}
                                        className="search-input"
                                    />
                                    <div className="user-study-material-list">
                                        <div className="material-grid">
                                            {filteredStudyMaterials().length > 0 ? (
                                                filteredStudyMaterials().map((material) => (
                                                    <div key={material.id} className="user-study-material-box">
                                                        <a href={material.url} target="_blank" rel="noopener noreferrer">
                                                            <div className="icon-container"><FaFileAlt /></div>
                                                        </a>
                                                        <p className="material-title">{material.title}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                <h2 style={{color:'#ff6f61', textAlign:'center', fontWeight:'bolder'}}>No content to display...</h2>
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                            {showModal && (
                                <div className="modal">
                                    <div className="modal-content">
                                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                                        <div className="modal-header">
                                            <h1 className="modal-title">{currentVideoTitle}</h1> {/* Display the current video title */}
                                        </div>
                                        <div className="video-player">
                                            <iframe
                                                title="Google Drive Video Player"
                                                src={videoUrl}
                                                width="100%"
                                                height="480"
                                                frameBorder="0"
                                                allowFullScreen
                                                sandbox="allow-same-origin allow-scripts"
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </StudentLayout>
    );
};

export default UserCourseAccess;
