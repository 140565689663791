




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import './AddCourseForm.css';
import AdminLayout from './AdminLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState(null);
  const { id } = useParams();
  const [videoUrl, setVideoUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          console.error('Access token not found');
          return;
        }
        
        const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        
        setCourseDetails(response.data);
      } catch (error) {
        console.error('Error fetching course details:', error.message);
      }
    };
  
    fetchCourseDetails();
  }, [id]);

  const handleVideoClick = (videoUrl) => {
    const fileId = videoUrl.match(/\/file\/d\/([^/]+)\//)[1];
    const embedUrl = `https://drive.google.com/file/d/${fileId}/preview?rm=minimal`;
    setVideoUrl(embedUrl);
    setShowModal(true);
  };

  const handleDeleteVideo = async (videoId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        await axios.delete(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/videos/${videoId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        
        setCourseDetails(prevCourseDetails => ({
          ...prevCourseDetails,
          videos: prevCourseDetails.videos.filter(video => video.id !== videoId)
        }));
        console.log('Video deleted successfully');
      } else {
        console.error('Access token not found');
      }
    } catch (error) {
      console.error('Error deleting video:', error.message);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const getFilteredAndSortedVideos = () => {
    const filteredVideos = courseDetails.videos.filter(video => 
      video.video_title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedVideos = filteredVideos.sort((a, b) => {
      if (sortOrder === 'asc') {
        return new Date(a.created_date_time) - new Date(b.created_date_time);
      } else {
        return new Date(b.created_date_time) - new Date(a.created_date_time);
      }
    });

    return sortedVideos;
  };

  const getPaginatedVideos = () => {
    const filteredAndSortedVideos = getFilteredAndSortedVideos();
    const startIndex = (currentPage - 1) * pageSize;
    return filteredAndSortedVideos.slice(startIndex, startIndex + pageSize);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(getFilteredAndSortedVideos().length / pageSize);
    const pagesToShow = 5;
    const currentPageGroup = Math.floor((currentPage - 1) / pagesToShow);
    const startPage = currentPageGroup * pagesToShow + 1;
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    const pages = [];
    for (let page = startPage; page <= endPage; page++) {
      pages.push(page);
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button
            className="btn btn-light"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        )}
        {pages.map(page => (
          <button
            key={page}
            className={`btn ${currentPage === page ? 'btn-dark' : 'btn-light'}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            className="btn btn-light"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        )}
      </div>
    );
  };

  if (!courseDetails) {
    return (
      <div className="loader">
        <div className="loader-text">Loading...</div>
      </div>
    );
  }

  const paginatedVideos = getPaginatedVideos();

  return (
    <>
    <AdminLayout>
    <div className='main-content'>
    <h2 className='title'>{courseDetails?.course_title}</h2><br />

      <div className='subscription-container'>
        <Link className="subscription-box" to={`/subscription-request/${courseDetails.id}`}>
          <div>
            <h3>Subscription Requests</h3> 
          </div>
        </Link>

        <Link className="subscribed-users-box" to={`/subscribed-users/${courseDetails.id}`}>
          <div>
            <h3>Subscribed Users</h3>
          </div>
        </Link>

        <Link className="live-class-list-box" to={`/live-class-list/${courseDetails.id}`}>
          <div>
            <h3>Live Classes</h3>
          </div>
        </Link>

        <Link className="study-material-box" to={`/study-material-list/${courseDetails.id}`}>
          <div>
            <h3>Study Material</h3>
          </div>
        </Link>
      </div>

      <div className='videos-box-container'>
        <div className="videos-box">
          <div className="video-controls">
            <input
              type="text"
              className="form-control search-bar"
              placeholder="Search by video title"
              value={searchQuery}
              onChange={handleSearch}
            />
            <button className='btn btn-light sort-button' onClick={handleSort}>
              Sort by Date {sortOrder === 'asc' ? '▲' : '▼'}
            </button>
            <Link className='btn btn-dark add-video-button' to={`/add-video/${courseDetails.id}`}>
              Add Video
            </Link>
          </div>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>Video Title</th>
                <th>Description</th>
                <th>Uploaded Date</th>
                <th>Play Video</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(paginatedVideos) && paginatedVideos.map((video) => (
                <tr key={video.id}>
                  <td>{video.video_title}</td>
                  <td>{video.video_description}</td>
                  <td>{new Date(video.created_date_time).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</td>
                  <td>
                    <div className="play-icon" onClick={() => handleVideoClick(video.video_url)}>
                      <FontAwesomeIcon icon={faPlayCircle} size="3x" />
                    </div>
                  </td>
                  <td>
                    <div className='btn-group'>
                      <button type='button' className='btn btn-dark dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'> </button>
                      <ul className='dropdown-menu'>
                        <Link className='dropdown-item' to={`/add-video/${courseDetails.id}/${video.id}`}>Edit</Link>
                        <button className='dropdown-item' onClick={() => handleDeleteVideo(video.id)}>Remove</button>
                      </ul>
                    </div>  
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {renderPagination()}
        </div>
        {showModal && (
          <div className="modal" style={{ display: showModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'fixed', zIndex: 9999, left: 0, top: 0, width: '100%', height: '100%', overflow: 'auto' }}>
            <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '15% auto', padding: '20px', border: '1px solid #888', width: '100%' }}>
              <span className="close" onClick={() => setShowModal(false)} style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer' }}>&times;</span>
              <div className="video-player" style={{ position: 'relative', zIndex: 2 }}>
                <iframe
                  title="Google Drive Video Player"
                  src={videoUrl}
                  width="100%"
                  height="480vh"
                  frameBorder="0"
                  allowFullScreen
                  sandbox="allow-same-origin allow-scripts"
                ></iframe>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    </AdminLayout>
    </>
  );
};

export default CourseDetails;


