// Home.js
import React from 'react';

const Home = () => {
  return (
    <div>
      <h2>Welcome Home!</h2>
      {/* ... (content for regular users) */}
    </div>
  );
};

export default Home;
