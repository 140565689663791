


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import StudentLayout from './StudentLayout';
// import './SubscribedCourses.css';

// const SubscribedCourses = () => {
//   const [subscribedCourses, setSubscribedCourses] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchSubscribedCourses = async () => {
//         try {
//             const accessToken = localStorage.getItem('accessToken');
//             if (accessToken) {
//                 const response = await axios.get('https://www.testometer.in:8080/api/v1/user/subscribed-courses', {
//                     headers: {
//                         Authorization: `Bearer ${accessToken}`,
//                     },
//                 });
//                 setSubscribedCourses(response.data);
//                 setLoading(false);
//             } else {
//                 console.error('Access token not found');
//             }
//         } catch (error) {
//             console.error('Error fetching subscribed courses:', error.message);
//         }
//     };

//     fetchSubscribedCourses();
// }, []);

//   if (loading) {
//     return (
//         <div className="user-loader">
//             <div className="user-spinner"></div>
//         </div>
//     );
// }

//   return (
//     <StudentLayout>
//       <div className="dashboard-container">
//         <h2 className="title">Subscribed Courses</h2>
//         <div className="dashboard-grid">
//           {subscribedCourses.map((course) => (
//             <Link key={course.id} to={`/access/${course.id}`} className="dashboard-card-link">
//               <div className="dashboard-card">
//                 <h3 className="dashboard-card-title">{course.course_title}</h3>
//                 <p className="dashboard-card-description">{course.title_description}</p>
//               </div>
//             </Link>
//           ))}
//         </div>
//       </div>
//     </StudentLayout>
//   );
// };

// export default SubscribedCourses;





import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import StudentLayout from './StudentLayout';
import './SubscribedCourses.css';

const SubscribedCourses = () => {
    const [subscribedCourses, setSubscribedCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSubscribedCourses = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    const response = await axios.get('https://www.testometer.in:8080/api/v1/user/subscribed-courses', {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setSubscribedCourses(response.data);
                    setLoading(false);
                } else {
                    console.error('Access token not found');
                }
            } catch (error) {
                console.error('Error fetching subscribed courses:', error.message);
                setLoading(false);
            }
        };

        fetchSubscribedCourses();
    }, []);

    if (loading) {
        return (
            <div className="user-loader">
                <div className="user-spinner"></div>
            </div>
        );
    }

    return (
        <StudentLayout>
            <div className="dashboard-container">
                <h2 className="title">Subscribed Courses</h2>
                <div className="dashboard-grid">
                    {subscribedCourses.length > 0 ? (
                        subscribedCourses.map((course) => (
                            <Link key={course.id} to={`/access/${course.id}`} className="dashboard-card-link">
                                <div className="dashboard-card">
                                    <h3 className="dashboard-card-title">{course.course_title}</h3>
                                    <p className="dashboard-card-description">{course.title_description}</p>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <h3 style={{color:'#ff6f61', textAlign:'center', fontWeight:'bolder'}}>You have not subscribed to any course</h3>
                    )}
                </div>
            </div>
        </StudentLayout>
    );
};

export default SubscribedCourses;
