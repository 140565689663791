 



import React, { useState } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import AuthService from '../Service/AuthService';
import { useAuth } from './AuthContext';

import logo from './logo.png';
import Footer from './Footer';

const SignInForm = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate(); 
  const { dispatch } = useAuth();

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (credentials.email.trim() === '') {
      newErrors.email = 'Email is required';
    }

    if (credentials.password.trim() === '') {
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      try {
        const response = await AuthService.signIn(credentials);
  
        if (response && response.token && response.refreshToken) {
          localStorage.setItem('accessToken', response.token);
          localStorage.setItem('refreshToken', response.refreshToken);
  
          dispatch({ type: 'LOGIN', payload: { user: response.user } });
          navigate(response.user.role === 'ADMIN' ? '/admin' : '/student-dash', { replace: true });
        } else {
          console.error('Signin error: Invalid response format - Missing token or refreshToken');
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setErrors(prevErrors => ({ ...prevErrors, credentials: 'Invalid email or password' }));
        } else {
          console.error('Signin error:', error.message);
        }
      }
    }
  };
  
  return (
    <>
      <div className='homepage-container'>
        <header>
            <nav className="navbar">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt='Company Logo' />
                  </Link>
                </div>

                <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <ul className={menuOpen ? "open" : ""}>
                  <li><NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home</NavLink></li>
                  <li><NavLink to="/sign-up">Sign Up</NavLink></li>
                </ul>
            </nav>
        </header>
      </div>

    <div className='signin-container'>
        <div className="form-header">
          <h3>Log in</h3>
        </div>
      <form onSubmit={handleSubmit}>
        <div className='signin-input-group'>
        <label>
          <input type="email" placeholder='Email ID' name="email" value={credentials.email} onChange={handleChange} />
          <div className="error">{errors.email}</div>
        </label>
        <label>
          <input type="password" placeholder='Enter password' name="password" value={credentials.password} onChange={handleChange} />
          <div className="error">{errors.password}</div>
        </label>
        <div className="error">{errors.credentials}</div> 
        </div>
        <button className='sign-up-button' type="submit">Log In</button>
      </form>
      <div className="link-container">
        <Link to="/sign-up">Don't have an account? Sign up</Link> <br></br>
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default SignInForm;
