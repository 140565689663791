



import React, { useEffect } from "react";
import SignUpForm from "./component/SignUpForm";
import SignInForm from "./component/SignInForm";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import AuthService from "./Service/AuthService";
import CourseList from "./component/CourseList";
import Home from "./component/Home";
import AdminDashboard from "./component/AdminDashboard";
import { AuthProvider } from './component/AuthContext';
import AddCourseForm from "./component/AddCourseForm";
import AdminCourseList from "./component/AdminCourseList";
import CourseDetails from "./component/CourseDetails";
import AddVideoToCourse from "./component/AddVideoToCourse";
import SubscriptionRequests from "./component/SubscriptionRequests";
import SubscribedUsers from "./component/SubscribedUsers";
import UserCourseDetails from "./component/UserCourseDetails";
import SubscribedCourses from "./component/SubscribedCourses";
import UserCourseAccess from "./component/UserCourseAccess";
import HomePage from "./component/HomePage";
import StudentDashboard from "./component/StudentDashboard";
import TotalSubscribedUsers from "./component/TotalSubscribedUsers";
import AddLiveClassToCourse from "./component/AddLiveClassToCourse";
import LiveClassList from "./component/LiveClassList";
import UserLiveClass from "./component/UserLiveClass";
import AttendanceList from "./component/AttendanceList";
import AddStudyMaterialToCourse from "./component/AddStudyMaterialToCourse";
import StudyMaterialList from "./component/StudyMaterialList";
import UserStudyMaterialList from "./component/UserStudyMaterialList";
import ForgotPassword from "./component/ForgotPassword";
import ResetPassword from "./component/ResetPassword";
import StudyMaterialViewer from "./component/StudyMaterialViewer";




const PrivateRoute = ({ element, role }) => {
  const isAuthenticated = AuthService.isAuthenticated(); 
  const userRole = AuthService.getUserRole(); 

 
  return isAuthenticated && userRole === role ? (
    element
  ) : (
    <Navigate to="/home-page" replace />
  );
};

function App() {
  useEffect(() => {
    // Check authentication status on page load
    const isAuthenticated = AuthService.isAuthenticated();

    if (!isAuthenticated) {
      // Redirect to sign-in page if not authenticated
      <Navigate to="/home-page" replace />
    } 
  }, []);

  return ( 
    <div>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/sign-up" element={<SignUpForm />} />
            <Route path="/home-page" element={<HomePage />} />
            <Route path="/sign-in" element={<SignInForm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/home" element={<PrivateRoute element={<Home />} role="USER" />} />
            <Route path="/admin" element={<PrivateRoute element={<AdminDashboard />} role="ADMIN" />} />
            <Route path="/course-list" element={<PrivateRoute element={<CourseList />} role="USER" />} />
            <Route path="/user-course/:id" element={<PrivateRoute element={<UserCourseDetails />} role="USER" />} />
            <Route path="/subscribed-courses" element={<PrivateRoute element={<SubscribedCourses />} role="USER" />} />
            <Route path="/student-Dash" element={<PrivateRoute element={<StudentDashboard />} role="USER" />} />
            <Route path="/access/:id" element={<PrivateRoute element={<UserCourseAccess />} role="USER" />} />
            <Route path="/user-live-class/:id" element={<PrivateRoute element={<UserLiveClass />} role="USER" />} />
            <Route path="/user-study-material/:id" element={<PrivateRoute element={<UserStudyMaterialList />} role="USER" />} />
            <Route path="/admin-course-list" element={<PrivateRoute element={<AdminCourseList />} role="ADMIN" />} />
            <Route path="/total-subscribed-users" element={<PrivateRoute element={<TotalSubscribedUsers />} role="ADMIN" />} />
            <Route path="/add-course" element={<PrivateRoute element={<AddCourseForm />} role="ADMIN" />} />
            <Route path="/add-study-material/:id" element={<PrivateRoute element={<AddStudyMaterialToCourse />} role="ADMIN" />} />
            <Route path="/edit-course/:id" element={<PrivateRoute element={<AddCourseForm />} role="ADMIN" />} />
            <Route path="/course-details/:id" element={<PrivateRoute element={<CourseDetails />} role="ADMIN" />} />
            <Route path="/subscription-request/:id" element={<PrivateRoute element={<SubscriptionRequests />} role="ADMIN" />} />
            <Route path="/subscribed-users/:id" element={<PrivateRoute element={<SubscribedUsers />} role="ADMIN" />} />
            <Route path="/add-video/:id" element={<PrivateRoute element={<AddVideoToCourse />} role="ADMIN" />} />
            <Route path="/add-video/:id/:videoId" element={<PrivateRoute element={<AddVideoToCourse />} role="ADMIN" />} />
            <Route path="/add-live-class/:id" element={<PrivateRoute element={<AddLiveClassToCourse />} role="ADMIN" />} />
            <Route path="/add-live-class/:id/:liveClassId" element={<PrivateRoute element={<AddLiveClassToCourse />} role="ADMIN" />} />
            <Route path="/live-class-list/:id" element={<PrivateRoute element={<LiveClassList />} role="ADMIN" />} />
            <Route path="/study-material-list/:id" element={<PrivateRoute element={<StudyMaterialList />} role="ADMIN" />} />
            <Route path="/study-material/:materialId/view" element={<PrivateRoute element={<StudyMaterialViewer />} role="ADMIN" />} />
            <Route path="/live-class-attendance/:id" element={<PrivateRoute element={<AttendanceList />} role="ADMIN" />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
