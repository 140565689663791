


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams, useNavigate, Link } from 'react-router-dom';
// import AdminLayout from './AdminLayout';

// const AddVideoToCourse = () => {
//   const { id, videoId } = useParams();
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     video_title: '',
//     video_url: '',
//     video_description: '',
//   });

//   useEffect(() => {
//     if (videoId) {
      
//       fetchVideoDetails(videoId);
//     }
//   }, [videoId]);

//   const fetchVideoDetails = async (videoId) => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/video/${videoId}`, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       const { data } = response;
      
//       setFormData({
//         video_title: data.video_title,
//         video_url: data.video_url,
//         video_description: data.video_description,
//       });
//     } catch (error) {
//       console.error('Error fetching video details:', error.message);
      
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const accessToken = localStorage.getItem('accessToken');

//       if (accessToken) {
//         if (videoId) {
          
//           await axios.put(`https://www.testometer.in:8080/api/v1/admin/courses/video/${videoId}`, formData, {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           });
//           console.log('Video updated successfully!');
//         } else {
          
//           await axios.post(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/videos`, formData, {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           });
//           console.log('Video added successfully!');
//         }
        
//         navigate(`/course-details/${id}`);
//       } else {
//         console.error('Access token not found');
//       }
//     } catch (error) {
//       console.error('Error adding/updating video:', error.message);
      
//     }
//   };

//   return (
//     <AdminLayout>
//       <div className="main-content">
//         <div className="add-video-container">
//           <h2>{videoId ? 'Edit Video' : 'Add Video to Course'}</h2>
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="video_title" className="form-label">Video Title:</label>
//               <input type="text" className="form-control" id="video_title" name="video_title" value={formData.video_title} onChange={handleChange} />
//             </div>

//             <div className="mb-3">
//               <label htmlFor="video_url" className="form-label">Video URL:</label>
//               <input type="text" className="form-control" id="video_url" name="video_url" value={formData.video_url} onChange={handleChange} />
//             </div>

//             <div className="mb-3">
//               <label htmlFor="video_description" className="form-label">Video Description:</label>
//               <input type="text" className="form-control" id="video_description" name="video_description" value={formData.video_description} onChange={handleChange} />
//             </div>

//             <button type="submit" className="btn btn-dark">{videoId ? 'Update Video' : 'Add Video'}</button>
//             <Link to={`/course-details/${id}`} className="btn btn-danger" style={{ marginLeft: '10px' }}>Cancel</Link>
//           </form>
//         </div>
//       </div>
//     </AdminLayout>
//   );
// };

// export default AddVideoToCourse;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const AddVideoToCourse = () => {
  const { id, videoId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    video_title: '',
    video_url: '',
    video_description: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (videoId) {
      fetchVideoDetails(videoId);
    }
  }, [videoId]);

  const fetchVideoDetails = async (videoId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/video/${videoId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { data } = response;
      setFormData({
        video_title: data.video_title,
        video_url: data.video_url,
        video_description: data.video_description,
      });
    } catch (error) {
      console.error('Error fetching video details:', error.message);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        if (videoId) {
          await axios.put(`https://www.testometer.in:8080/api/v1/admin/courses/video/${videoId}`, formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setSuccessMessage('Video updated successfully!');
        } else {
          await axios.post(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/videos`, formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setSuccessMessage('Video added successfully!');
        }
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate(`/course-details/${id}`);
        }, 2000);
      } else {
        console.error('Access token not found');
      }
    } catch (error) {
      console.error('Error adding/updating video:', error.message);
    }
  };

  return (
    <AdminLayout>
      <div className="main-content">
        <div className="add-video-container">
          <h2>{videoId ? 'Edit Video' : 'Add Video to Course'}</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="video_title" className="form-label">Video Title:</label>
              <input type="text" className="form-control" id="video_title" name="video_title" value={formData.video_title} onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="video_url" className="form-label">Video URL:</label>
              <input type="text" className="form-control" id="video_url" name="video_url" value={formData.video_url} onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="video_description" className="form-label">Video Description:</label>
              <input type="text" className="form-control" id="video_description" name="video_description" value={formData.video_description} onChange={handleChange} />
            </div>
            <button type="submit" className="btn btn-dark">{videoId ? 'Update Video' : 'Add Video'}</button>
            <Link to={`/course-details/${id}`} className="btn btn-danger" style={{ marginLeft: '10px' }}>Cancel</Link>
          </form>
        </div>
        {showModal && (
          <div className="success-modal">
            <div className="success-modal-content">
              <span className="modal-close" onClick={() => setShowModal(false)} style={{color:'black'}}>&times;</span>
              <p style={{color:'black'}}>{successMessage}</p>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AddVideoToCourse;








