

  

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const SubscribedUsers = () => {
  const { id } = useParams();
  const [subscribedUsers, setSubscribedUsers] = useState([]);
  const [removingUser, setRemovingUser] = useState(null);

  const fetchData = async () => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/subscribed-users`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (response.data && Array.isArray(response.data)) {
            setSubscribedUsers(response.data);
        } else {
            console.error('Invalid API response:', response.data);
        }
    } catch (error) {
        console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleRemoveUser = async (userId) => {
    const confirmed = window.confirm('Are you sure you want to remove this user from the subscribed list?');

    if (!confirmed) {
      return; 
    }

    try {
      setRemovingUser(userId); // Set the user being removed
      const accessToken = localStorage.getItem('accessToken');
      await axios.delete(`https://www.testometer.in:8080/api/v1/admin/unsubscribe/${id}/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Refetch data after successful removal
      fetchData();
    } catch (error) {
      console.error('Error removing user:', error.message);
    } finally {
      setRemovingUser(null); // Reset removingUser state
    }
  };

  return (
    <>
      <AdminLayout>
        <div className='main-content'>
          <div className="subscribed-users-container">
            <h2>Subscribed Users for Course</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Student ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subscribedUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.student_id}</td>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>
                      <button 
                        onClick={() => handleRemoveUser(user.id)} 
                        className="btn btn-danger" 
                        disabled={removingUser === user.id}
                      >
                        {removingUser === user.id ? 'Removing...' : 'Remove'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default SubscribedUsers;




