




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import AdminLayout from './AdminLayout';
// import './AddCourseForm.css';

// const AdminCourseList = () => {
//   const [courses, setCourses] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [coursesPerPage] = useState(5);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [sortOrder, setSortOrder] = useState('asc'); // Initialize sortOrder state

//   const fetchAdminCourseList = async () => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');
//       if (accessToken) {
//         const response = await axios.get('https://www.testometer.in:8080/api/v1/admin/courses', {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });
//         setCourses(response.data);
//       } else {
//         console.error('Access token not found');
//       }
//     } catch (error) {
//       console.error('Error fetching admin course list data:', error.message);
//     }
//   };

//   useEffect(() => {
//     fetchAdminCourseList();
//   }, []);

//   const handleDelete = async (id) => {
//     try {
//       const accessToken = localStorage.getItem('accessToken');

//       if (accessToken) {
//         const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/subscribed-users`, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         if (response.data.length === 0) {
//           await axios.delete(`https://www.testometer.in:8080/api/v1/admin/courses/${id}`, {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           });

//           fetchAdminCourseList();
//         } else {
//           window.alert('Cannot delete course because users are subscribed to it');
//         }
//       } else {
//         console.error('Access token not found');
//       }
//     } catch (error) {
//       console.error('Error deleting course:', error.message);
//     }
//   };

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//     setCurrentPage(1); // Reset to the first page on new search
//   };

//   const handleSort = () => {
//     const sortedCourses = [...courses].sort((a, b) => {
//       const dateA = new Date(a.created_date_time);
//       const dateB = new Date(b.created_date_time);
//       return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
//     });
//     setCourses(sortedCourses);
//     setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
//   };

//   const filteredCourses = courses.filter(course =>
//     course.course_title.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const indexOfLastCourse = currentPage * coursesPerPage;
//   const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
//   const currentCourses = filteredCourses.slice(indexOfFirstCourse, indexOfLastCourse);

//   const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);

//   const renderPagination = () => {
//     const pageNumbers = [];
//     const maxPagesToShow = 5;
//     const halfRange = Math.floor(maxPagesToShow / 2);
//     let startPage = Math.max(1, currentPage - halfRange);
//     let endPage = Math.min(totalPages, currentPage + halfRange);

//     if (currentPage - halfRange < 1) {
//       endPage = Math.min(totalPages, endPage + (halfRange - (currentPage - 1)));
//     }

//     if (currentPage + halfRange > totalPages) {
//       startPage = Math.max(1, startPage - (currentPage + halfRange - totalPages));
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       pageNumbers.push(
//         <button
//           key={i}
//           onClick={() => setCurrentPage(i)}
//           className={`btn ${currentPage === i ? 'btn-dark' : 'btn-light'}`}
//         >
//           {i}
//         </button>
//       );
//     }

//     return (
//       <div className="pagination">
//         {currentPage > 1 && (
//           <button
//             className="btn btn-light"
//             onClick={() => setCurrentPage(currentPage - 1)}
//           >
//             &lt;
//           </button>
//         )}
//         {pageNumbers}
//         {currentPage < totalPages && (
//           <button
//             className="btn btn-light"
//             onClick={() => setCurrentPage(currentPage + 1)}
//           >
//             &gt;
//           </button>
//         )}
//       </div>
//     );
//   };

//   return (
//     <>
//       <AdminLayout>
//         <div className='main-content'>
          
//           <h2 className='title'>Course List</h2>
//           <div className='videos-box-container'>
//             <div className='videos-box'>
//               <Link className='btn btn-dark float-right' to={'/add-course'}>Add Course</Link>
//               <br /><br />
//               <input
//                 type="text"
//                 className="form-control search-bar"
//                 placeholder="Search by Course Title"
//                 value={searchQuery}
//                 onChange={handleSearch}
//                 style={{ marginBottom: '20px', padding: '10px', width: '100%' }}
//               />
//               <table className="table table-bordered">
//                 <thead>
//                   <tr>
//                     <th>Course ID</th>
//                     <th>Course Title</th>
//                     <th>Description</th>
//                     <th onClick={handleSort} style={{ cursor: 'pointer' }}>
//                       Created Date {sortOrder === 'asc' ? '↑' : '↓'}
//                     </th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentCourses.map((course) => (
//                     <tr key={course.id}>
//                       <td>{course.id}</td>
//                       <td><Link to={`/course-details/${course.id}`} style={{ textDecoration: 'none' }}>{course.course_title}</Link></td>
//                       <td>{course.title_description}</td>
//                       <td>{new Date(course.created_date_time).toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</td>
//                       <td>
//                         <div className='btn-group'>
//                           <button type='button' className='btn btn-dark dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'></button>
//                           <ul className='dropdown-menu'>
//                             <li><Link className="dropdown-item" to={`/edit-course/${course.id}`}> Edit </Link></li>
//                             <button className='dropdown-item' onClick={() => handleDelete(course.id)}> Remove </button>
//                           </ul>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               {renderPagination()}
//             </div>
//           </div>
//         </div>
//       </AdminLayout>
//     </>
//   );
// };

// export default AdminCourseList;









import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import './Admin.css';

const AdminCourseList = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // Initialize sortOrder state

  const fetchAdminCourseList = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        const response = await axios.get('https://www.testometer.in:8080/api/v1/admin/courses', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setCourses(response.data);
      } else {
        console.error('Access token not found');
      }
    } catch (error) {
      console.error('Error fetching admin course list data:', error.message);
    }
  };

  useEffect(() => {
    fetchAdminCourseList();
  }, []);

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/subscribed-users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.data.length === 0) {
          await axios.delete(`https://www.testometer.in:8080/api/v1/admin/courses/${id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          fetchAdminCourseList();
        } else {
          window.alert('Cannot delete course because users are subscribed to it');
        }
      } else {
        console.error('Access token not found');
      }
    } catch (error) {
      console.error('Error deleting course:', error.message);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleSort = () => {
    const sortedCourses = [...courses].sort((a, b) => {
      const dateA = new Date(a.created_date_time);
      const dateB = new Date(b.created_date_time);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setCourses(sortedCourses);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const filteredCourses = courses.filter(course =>
    course.course_title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = filteredCourses.slice(indexOfFirstCourse, indexOfLastCourse);

  const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfRange = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, currentPage + halfRange);

    if (currentPage - halfRange < 1) {
      endPage = Math.min(totalPages, endPage + (halfRange - (currentPage - 1)));
    }

    if (currentPage + halfRange > totalPages) {
      startPage = Math.max(1, startPage - (currentPage + halfRange - totalPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`btn ${currentPage === i ? 'btn-dark' : 'btn-light'}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button
            className="btn btn-light"
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            &lt;
          </button>
        )}
        {pageNumbers}
        {currentPage < totalPages && (
          <button
            className="btn btn-light"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <AdminLayout>
        <div className='main-content'>
      {/* <div className='admin-course-list-container'> */}
      
        <h2 className='admin-course-list-title'>Course List</h2>
        <div className='admin-course-box-container'>
          {/* <div className='admin-course-box'> */}


            <Link className='admin-course-add-btn' to={'/add-course'}>Add Course</Link>

            <input
              type="text"
              className="admin-course-search-bar"
              placeholder="Search by Course Title"
              value={searchQuery}
              onChange={handleSearch}
            />
            <table className="admin-course-table">
              <thead>
                <tr>
                  <th>Course ID</th>
                  <th>Course Title</th>
                  <th>Description</th>
                  <th onClick={handleSort} style={{ cursor: 'pointer' }}>
                    Created Date {sortOrder === 'asc' ? '↑' : '↓'}
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentCourses.map((course) => (
                  <tr key={course.id}>
                    <td>{course.id}</td>
                    <td><Link to={`/course-details/${course.id}`} style={{ textDecoration: 'none' }}>{course.course_title}</Link></td>
                    <td>{course.title_description}</td>
                    <td>{new Date(course.created_date_time).toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</td>
                    <td>
                      <div className='btn-group admin-course-btn-group'>
                        <button type='button' className='btn btn-dark dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'></button>
                        <ul className='dropdown-menu'>
                          <li><Link className="dropdown-item admin-course-dropdown-item" to={`/edit-course/${course.id}`}> Edit </Link></li>
                          <button className='dropdown-item admin-course-dropdown-item' onClick={() => handleDelete(course.id)}> Remove </button>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {renderPagination()}
          {/* </div> */}
        </div>
      {/* </div> */}
      </div>
    </AdminLayout>
    </>
  );
};

export default AdminCourseList;