



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import './AttendanceList.css'; // Import the CSS file for styling

const AttendanceList = () => {
  const { id } = useParams();
  const [attendanceData, setAttendanceData] = useState({});
  const [dates, setDates] = useState([]);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleDownloadAttendance = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/live-class/${id}/excel`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          fromDate,
          toDate,
        },
        responseType: 'blob', // Important: Set responseType to 'blob' for binary response
      });

      // Create a URL for the blob response
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'attendance.xlsx');
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading attendance:', error.message);
      // Handle error (display error message, etc.)
    }
  };

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found');
        }

        const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/live-classes/${id}/attendance`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const allDates = Object.keys(response.data);
        const students = {};

        // Iterate through each date and student to build attendance data
        for (const date in response.data) {
          response.data[date].forEach(record => {
            const studentId = record.user.id;
            const studentName = `${record.user.first_name} ${record.user.last_name}`;

            // Initialize student object if not exists
            if (!students[studentId]) {
              students[studentId] = {
                name: studentName,
                attendance: {},
              };
            }

            // Mark attendance for the current date
            students[studentId].attendance[date] = true;
          });
        }

        setAttendanceData(students);
        setDates(allDates);
      } catch (error) {
        console.error('Error fetching attendance data:', error.message);
        setError(error.message);
      }
    };

    fetchAttendanceData();
  }, [id]);

  return (
    <AdminLayout>
      <div className="main-content">
        <h2>Attendance for Live Class ID: {id}</h2>

        <div className="attendance-download-box">
          <div className="date-input-container">
            <label htmlFor="fromDate">Select Date Range From - </label>
            <input type="date" id="fromDate" value={fromDate} onChange={e => setFromDate(e.target.value)} />
          </div>
          <div className="date-input-container">
            <label htmlFor="toDate">To Date - </label>
            <input type="date" id="toDate" value={toDate} onChange={e => setToDate(e.target.value)} />
          </div>
          <div className='button-container'>
            <button className="download-button" onClick={handleDownloadAttendance}>Download Attendance</button>
          </div>
          
        </div>

        {error && <p>Error: {error}</p>}

        <table className="attendance-table">
          <thead>
            <tr>
              <th>Student Name</th>
              {dates.map(date => (
                <th key={date}>{date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(attendanceData).map(student => (
              <tr key={student.name}>
                <td>{student.name}</td>
                {dates.map(date => (
                  <td key={date} className={student.attendance[date] ? 'P' : 'A'}>{student.attendance[date] ? 'P' : 'A'}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
};

export default AttendanceList;















