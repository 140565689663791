




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminLayout from './AdminLayout';
import './AddCourseForm.css';  // Import the CSS for styling

const TotalSubscribedUsers = () => {
  const [subscribedUsers, setSubscribedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchSubscribedUsers = async () => {
      try {
        let subscribedUsersData = JSON.parse(localStorage.getItem('subscribedUsersData'));
        if (!subscribedUsersData) {
          const accessToken = localStorage.getItem('accessToken');
          if (!accessToken) {
            console.error('Access token not found');
            return;
          }
          
          const response = await axios.get('https://www.testometer.in:8080/api/v1/admin/subscribed-users', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          
          subscribedUsersData = response.data;
          localStorage.setItem('subscribedUsersData', JSON.stringify(subscribedUsersData));
        }
        
        setSubscribedUsers(subscribedUsersData);
      } catch (error) {
        console.error('Error fetching or storing subscribed users data:', error.message);
      }
    };
  
    fetchSubscribedUsers();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const filteredUsers = subscribedUsers.filter(user => {
    const studentId = user.student_id ? user.student_id.toString() : '';
    const firstName = user.first_name ? user.first_name.toLowerCase() : '';
    const lastName = user.last_name ? user.last_name.toLowerCase() : '';
    const email = user.email ? user.email.toLowerCase() : '';
    const query = searchQuery.toLowerCase();

    return (
      studentId.includes(query) ||
      firstName.includes(query) ||
      lastName.includes(query) ||
      email.includes(query)
    );
  });

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfRange = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, currentPage + halfRange);

    if (currentPage - halfRange < 1) {
      endPage = Math.min(totalPages, endPage + (halfRange - (currentPage - 1)));
    }

    if (currentPage + halfRange > totalPages) {
      startPage = Math.max(1, startPage - (currentPage + halfRange - totalPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`btn ${currentPage === i ? 'btn-dark' : 'btn-light'}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button
            className="btn btn-light"
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            &lt;
          </button>
        )}
        {pageNumbers}
        {currentPage < totalPages && (
          <button
            className="btn btn-light"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  return (
    <AdminLayout>
      <div className='main-content'>
      <h2 style={{ textAlign: 'center' }} className='title'>Total Subscribed Users</h2>
        
        <div className='videos-box-container'>
          <div className="videos-box">
            
            <input
              type="text"
              className="form-control search-bar"
              placeholder="Search by Name, Email or ID"
              value={searchQuery}
              onChange={handleSearch}
              style={{ marginBottom: '20px', padding: '10px', width: '100%' }}
            />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Student ID</th>
                  <th>Student Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map(user => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.student_id}</td>
                    <td>{user.first_name} {user.last_name}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {renderPagination()}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default TotalSubscribedUsers;



