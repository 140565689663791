



// import React, { useState, useEffect } from "react";
// import { useMediaQuery } from 'react-responsive'; 
// import MobileNavbar from './MobileNavbar'; 
// import { FaTh, FaBook, FaBookReader, FaSignOutAlt, FaBars, FaUser } from "react-icons/fa";
// import { NavLink, useNavigate } from "react-router-dom";
// import AuthService from '../Service/AuthService';
// import './StudentSidebar.css';
// import logo from './logo.png';

// const StudentSidebar = ({ isOpen, toggleSidebar, children }) => {
//   const isMobile = useMediaQuery({ maxWidth: 768 });
//   const [user, setUser] = useState(null);
//   const [showProfileModal, setShowProfileModal] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//          const userData = JSON.parse(localStorage.getItem('user'));
//          setUser(userData);
//   }, []);

//   const handleViewProfile = () => {
//    setShowProfileModal(!showProfileModal);
//   };
//   const handleCloseProfileModal = () => {
//     setShowProfileModal(false);
//   };

//   const handleSignOut = () => {
//         AuthService.signOut();
//         navigate('/sign-in');
//   };
  
//   // Define your menu items with icons
//   const menuItem = [
//     {
//       path: '/profile',
//       name: 'Profile',
//       icon: <FaUser />,
//       onClick: handleViewProfile,
//     },
//     {  
//       path: "/student-dash",
//       name: "Dashboard",
//       icon: <FaTh />,
//     },
//     {
//       path: "/course-list",
//       name: "Courses",
//       icon: <FaBook />,
//     },
//     {
//       path: "/subscribed-courses",
//       name: "Subscribed",
//       icon: <FaBookReader />,
//     },
//     // {
//     //   path: "/sign-in",
//     //   name: "Sign Out",
//     //   icon: <FaSignOutAlt />,
//     // },
//   ];

//   return (
//     <div>
//       {isMobile ? (
//         <MobileNavbar toggleSidebar={toggleSidebar} menuItem={menuItem} />
//       ) : (
//         <div className={`sidebar ${isOpen ? "open" : ""}`} style={{ width: isOpen ? "250px" : "90px" }}>
//           <div className="top_section">
//             <h1 className="logo" style={{ display: isOpen ? "block" : "none" }}><img src={logo} alt="Logo" /></h1>
//             <div className="bars" style={{ marginLeft: isOpen ? "80px" : "0px" }}>
//               <FaBars onClick={toggleSidebar} />
//             </div>
//           </div>
//           <div className="sidebar-content">
//             {menuItem.map((item, index) => (
//               item.path === '/profile' ? (
//                 <div key={index} className="link" onClick={item.onClick}>
//                   <div className="icon">{item.icon}</div>
//                   <div className="link_text" style={{ display: isOpen ? "block" : "none"}}>{item.name}</div>
//                 </div>
//               ) : (
//                 <NavLink to={item.path} key={index} className="link" activeClassName="active">
//                   <div className="icon">{item.icon}</div>
//                   <div className="link_text" style={{ display: isOpen ? "block" : "none"}}>{item.name}</div>
//                 </NavLink>
//               )
//             ))}
//           </div>
//         </div>
//       )}
//       <main style={{ marginLeft: isMobile ? (isOpen ? '250px' : '0px') : (isOpen ? '30px' : '120px') }}>
//           {children}
//       </main>

//       {/* Profile modal */}
//       {showProfileModal && (
//         <div className="modal" style={{ display: 'block' }}>
//           <div className="modal-content">
//             <span className="close" onClick={handleCloseProfileModal}>&times;</span>
//             <h2 style={{color:'black'}}>Profile</h2>
//             {user && (
//               <div>
//                 <p style={{color:'black'}}>Name: {user.first_name} {user.last_name}</p>
//                 <p style={{color:'black'}}>Email: {user.email}</p>
//                 <br></br>
//                 <button className='btn btn-dark' onClick={handleSignOut}>Sign Out</button>
//               </div>
//             )}
//           </div>
//         </div>
//       )}

//     </div>
//   );
// };

// export default StudentSidebar;








import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive'; 
import MobileNavbar from './MobileNavbar'; 
import { FaTh, FaBook, FaBookReader, FaSignOutAlt, FaBars, FaUser } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import AuthService from '../Service/AuthService';
import './StudentSidebar.css';
import logo from './logo.png';
// import dummyProfilePic from './dummyProfilePic.png'; 
import Profile from './Profile.jpg'

const StudentSidebar = ({ isOpen, toggleSidebar, children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [user, setUser] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
         const userData = JSON.parse(localStorage.getItem('user'));
         setUser(userData);

  }, []);


  const handleViewProfile = () => {
   setShowProfileModal(!showProfileModal);
  };
  const handleCloseProfileModal = () => {
    setShowProfileModal(false);
  };

  const handleSignOut = () => {
        AuthService.signOut();
        navigate('/sign-in');
  };
  
  const menuItem = [
    {
      path: '/profile',
      name: 'Profile',
      icon: <FaUser />,
      onClick: handleViewProfile,
    },
    {  
      path: "/student-dash",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/course-list",
      name: "Courses",
      icon: <FaBook />,
    },
    {
      path: "/subscribed-courses",
      name: "Subscribed",
      icon: <FaBookReader />,
    },
  ];

  return (
    <div>
      {isMobile ? (
        <MobileNavbar toggleSidebar={toggleSidebar} menuItem={menuItem} />
      ) : (
        <div className={`sidebar ${isOpen ? "open" : ""}`} style={{ width: isOpen ? "250px" : "90px" }}>
          <div className="top_section">
            <h1 className="logo" style={{ display: isOpen ? "block" : "none" }}><img src={logo} alt="Logo" /></h1>
            <div className="bars" style={{ marginLeft: isOpen ? "80px" : "0px" }}>
              <FaBars onClick={toggleSidebar} />
            </div>
          </div>
          <div className="sidebar-content">
            {menuItem.map((item, index) => (
              item.path === '/profile' ? (
                <div key={index} className="link" onClick={item.onClick}>
                  <div className="icon">{item.icon}</div>
                  <div className="link_text" style={{ display: isOpen ? "block" : "none"}}>{item.name}</div>
                </div>
              ) : (
                <NavLink to={item.path} key={index} className="link" activeClassName="active">
                  <div className="icon">{item.icon}</div>
                  <div className="link_text" style={{ display: isOpen ? "block" : "none"}}>{item.name}</div>
                </NavLink>
              )
            ))}
          </div>
        </div>
      )}
      <main style={{ marginLeft: isMobile ? (isOpen ? '250px' : '0px') : (isOpen ? '30px' : '120px') }}>
          {children}
      </main>

      {/* Profile modal */}
      {showProfileModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-content">
            <span className="close" onClick={handleCloseProfileModal}>&times;</span>
            <div className="profile-header">
              <h2 className="profile-title">User profile</h2>
            </div>
            {user && (
              <div className="profile-details">
                <div className="profile-image">
                  <img src={Profile} alt="Profile" />
                </div>
                <div className="profile-info">
                  <p><strong>Name:</strong> {user.first_name} {user.last_name}</p>
                  <p><strong>Email:</strong> {user.email}</p>
                </div>
              </div>
            )}
            <br></br>
            <button className="btn btn-dark" onClick={handleSignOut}>Sign Out</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentSidebar;




















