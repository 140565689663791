


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const AddCourseForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        course_title: '',
        description: '',
        title_description: '',
    });

    

    const isEditMode = !!id;

    useEffect(() => {
        
        const fetchCourseDetails = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken && isEditMode) {
                    const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/${id}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setFormData(response.data);
                }
            } catch (error) {
                console.error('Error fetching course details:', error.message);
            }
        };

        fetchCourseDetails();
    }, [id, isEditMode]);

    const handleChange = (e) => {
       
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                const method = isEditMode ? 'put' : 'post';
                const url = isEditMode ? `https://www.testometer.in:8080/api/v1/admin/courses/${id}` : 'https://www.testometer.in:8080/api/v1/admin/courses';

                await axios[method](url, formData, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setSuccessMessage(`Course ${isEditMode ? 'updated' : 'added'} successfully!`);
                setFormData({
                    course_title: '',
                    description: '',
                    title_description: '',
                });

                setShowModal(true);
                 setTimeout(() => {
                   setShowModal(false);
                   navigate('/admin-course-list');
                 }, 2000);

                
            } else {
                console.error('Access token not found');
            }
        } catch (error) {
            console.error('Error adding course:', error.message);
        }
    };

    return (
        <>
            <AdminLayout>
                <div className='main-content'>
                    <div className="add-course-container">
                        <h2>{isEditMode ? 'Edit Course' : 'Add Course'}</h2>
                        {successMessage && <div className="success-message">{successMessage}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor='exampleFormControlInput1' className='form-label'>Course Title:</label>
                                <input type="text" className='form-control' id="exampleFormControlInput1" name="course_title" value={formData.course_title} onChange={handleChange} />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='exampleFormControlTextarea1' className='form-label'>Title Description:</label>
                                <textarea name="title_description" className='form-control' id="exampleFormControlTextarea1" rows="3" value={formData.title_description } onChange={handleChange}></textarea>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='exampleFormControlTextarea1' className='form-label'>Description:</label>
                                <textarea name="description" className='form-control' id="exampleFormControlTextarea1" rows="3" value={formData.description} onChange={handleChange}></textarea>
                            </div>
                            {/* Replace file input with input field for image URL */}
                            
                            <button className='btn btn-primary' type="submit">{isEditMode ? 'Update Course' : 'Add Course'}</button>
                            <br></br>
                            <Link className='btn btn-danger' to={'/admin-course-list'}>Cancel</Link>
                        </form>
                    </div>
                    {showModal && (
                       <div className="success-modal">
                         <div className="success-modal-content">
                           <span className="modal-close" onClick={() => setShowModal(false)} style={{color:'black'}}>&times;</span>
                           <p style={{color:'black'}}>{successMessage}</p>
                         </div>
                       </div>
                     )}
                </div>
            </AdminLayout>
        </>
    );
};

export default AddCourseForm;

