import React from 'react';
import './Footer.css'; // Make sure to create this CSS file for the footer styles

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <h4>About Us</h4>
        <p>
          Welcome to TestoMeter! Here we are not just a firm we are a bunch of highly experienced experts in software testing who volunteer their time to the development, maintenance and promotion of the online training program. In the training program, our mission is to promote professionalism in software testing by providing quality training for the ISTQB certification. We support and facilitate training providers in delivering high-quality courses, by actively engaging in the ISTQB groups, and by putting efforts to develop and encourage people who are in or are deciding to start their career in software testing.
        </p>
      </div>
      <div className="footer-right">
        <div className="contact-us">
          <h4>Contact Us</h4>
          <p>Email: contact@testometer.co.in</p>
          <p>Phone: +91 9096153227 / 8446970524</p>
        </div>
        <div className="address">
          <h4>Address</h4>
          <p>Kamalraj Pasaydan, 2nd Floor, Office No D207, Alandi Rd, above Global Mart, Dighi</p>
          <p>Pune, Maharashtra, 411015</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
