// // AddLiveClassToCourse.jsx

// import React, { useState } from 'react';
// import axios from 'axios';
// import { useParams, useNavigate, Link } from 'react-router-dom';
// import AdminLayout from './AdminLayout';

// const AddLiveClassToCourse = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [formData, setFormData] = useState({
//         class_title: '',
//         meeting_link: '',
//         description: '',
//         start_date: '',
//         start_time: '',
//     });

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const accessToken = localStorage.getItem('accessToken');

//             if (accessToken) {
//                 await axios.post(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/live-class`, formData, {
//                     headers: {
//                         Authorization: `Bearer ${accessToken}`,
//                     },
//                 });

//                 // Optionally, you can add a success message or redirect to the course details page
//                 console.log('Live class added successfully!');
//                 navigate(`/course-details/${id}`);
//             } else {
//                 console.error('Access token not found');
//             }
//         } catch (error) {
//             console.error('Error adding live class:', error.message);
//             // Handle error (display error message, etc.)
//         }
//     };

//     return (
//         <>
//             <AdminLayout>
//                 <div className='main-content'>
//                     <div className="add-live-class-container">
//                         <h2>Add Live Class to Course</h2>
//                         <form onSubmit={handleSubmit}>
//                             <div className='mb-3'>
//                                 <label htmlFor='class_title' className='form-label'>Class Title:</label>
//                                 <input type="text" className='form-control' id="class_title" name="class_title" value={formData.class_title} onChange={handleChange} />
//                             </div>

//                             <div className='mb-3'>
//                                 <label htmlFor='meeting_link' className='form-label'>Meeting Link:</label>
//                                 <input type="text" className='form-control' id="meeting_link" name="meeting_link" value={formData.meeting_link} onChange={handleChange} />
//                             </div>

//                             <div className='mb-3'>
//                                 <label htmlFor='description' className='form-label'>Description:</label>
//                                 <input type="text" className='form-control' id="description" name="description" value={formData.description} onChange={handleChange} />
//                             </div>

//                             <div className='mb-3'>
//                                 <label htmlFor='start_date' className='form-label'>Start Date:</label>
//                                 <input type="date" className='form-control' id="start_date" name="start_date" value={formData.start_date} onChange={handleChange} />
//                             </div>

//                             <div className='mb-3'>
//                                 <label htmlFor='start_time' className='form-label'>Start Time:</label>
//                                 <input type="time" className='form-control' id="start_time" name="start_time" value={formData.start_time} onChange={handleChange} />
//                             </div>

//                             <button className='btn btn-dark' type="submit">Add Live Class</button>
//                             <Link className='btn btn-danger' to={`/live-class-list/${id}`} style={{ marginLeft: '10px' }}>Cancel</Link>
//                         </form>
//                     </div>
//                 </div>
//             </AdminLayout>
//         </>
//     );
// };

// export default AddLiveClassToCourse;





// AddLiveClassToCourse.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const AddLiveClassToCourse = () => {
    const { id, liveClassId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        class_title: '',
        meeting_link: '',
        description: '',
        start_date: '',
        start_time: '',
    });

    useEffect(() => {
        if (liveClassId) {
            // Fetch live class details if liveClassId is provided
            const fetchData = async () => {
                try {
                    const accessToken = localStorage.getItem('accessToken');

                    if (accessToken) {
                        const response = await axios.get(`https://www.testometer.in:8080/api/v1/admin/courses/live-class/${liveClassId}`, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                        setFormData(response.data);
                    } else {
                        console.error('Access token not found');
                    }
                } catch (error) {
                    console.error('Error fetching live class details:', error.message);
                    // Handle error (display error message, etc.)
                }
            };

            fetchData();
        }
    }, [liveClassId]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken) {
                if (liveClassId) {
                    // Update existing live class if liveClassId is provided
                    await axios.put(`https://www.testometer.in:8080/api/v1/admin/courses/live-class/${liveClassId}`, formData, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    console.log('Live class updated successfully!');
                } else {
                    // Add new live class if liveClassId is not provided
                    await axios.post(`https://www.testometer.in:8080/api/v1/admin/courses/${id}/live-class`, formData, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    console.log('Live class added successfully!');
                }
                navigate(`/course-details/${id}`);
            } else {
                console.error('Access token not found');
            }
        } catch (error) {
            console.error('Error adding/updating live class:', error.message);
            // Handle error (display error message, etc.)
        }
    };

    return (
        <>
            <AdminLayout>
                <div className='main-content'>
                    <div className="add-live-class-container">
                        <h2>{liveClassId ? 'Edit Live Class' : 'Add Live Class to Course'}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor='class_title' className='form-label'>Class Title:</label>
                                <input type="text" className='form-control' id="class_title" name="class_title" value={formData.class_title} onChange={handleChange} />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor='meeting_link' className='form-label'>Meeting Link:</label>
                                <input type="text" className='form-control' id="meeting_link" name="meeting_link" value={formData.meeting_link} onChange={handleChange} />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor='description' className='form-label'>Description:</label>
                                <input type="text" className='form-control' id="description" name="description" value={formData.description} onChange={handleChange} />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor='start_date' className='form-label'>Start Date:</label>
                                <input type="date" className='form-control' id="start_date" name="start_date" value={formData.start_date} onChange={handleChange} />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor='start_time' className='form-label'>Start Time:</label>
                                <input type="time" className='form-control' id="start_time" name="start_time" value={formData.start_time} onChange={handleChange} />
                            </div>

                            <button className='btn btn-dark' type="submit">{liveClassId ? 'Update Live Class' : 'Add Live Class'}</button>
                            <Link className='btn btn-danger' to={`/live-class-list/${id}`} style={{ marginLeft: '10px' }}>Cancel</Link>
                        </form>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default AddLiveClassToCourse;

