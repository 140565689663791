

// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import './CourseList.css'; // Import the CSS file
// import StudentLayout from './StudentLayout';

// const CourseList = () => {
//   const [courses, setCourses] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     const fetchCourseList = async () => {
//       try {
//         const accessToken = localStorage.getItem('accessToken');
//         if (accessToken) {
//           const response = await axios.get('https://www.testometer.in:8080/api/v1/user/courses', {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           });
//           setCourses(response.data);
//         } else {
//           setError('Access token not found');
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching course list:', error.message);
//         setError(error.message);
//       }
//     };

//     fetchCourseList();
//   }, []);

//   const filteredCourses = courses.filter(course =>
//     course.course_title.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const handleSearch = event => {
//     setSearchQuery(event.target.value);
//   };

//   if (loading) {
//     return (
//       <div className="user-loader">
//         <div className="user-spinner"></div>
//       </div>
//     );
//   }

//   return (
//     <StudentLayout>
//       <div className="student-main-content">
//         <br></br>
//         <h3 className="title">Available Courses</h3>
//         {error && <p>Error: {error}</p>}
//         <input
//                type="text"
//                placeholder="Search by course title"
//                value={searchQuery}
//                onChange={handleSearch}
//                style={{ backgroundColor: 'white', color: 'black' }}
//               />
        
//         <div className="course-list-container">
//           {filteredCourses.map(course => (
//             <Link key={course.id} to={`/user-course/${course.id}`} className="course-link" style={{ textDecoration: 'none' }}>
//               <div className="course-box">
//                 <h3 className="course-heading">{course.course_title}</h3>
//                 <p className="course-description">{course.title_description}</p>
//               </div>
//             </Link>
//           ))}
        
//         </div>
//       </div>
//     </StudentLayout>
//   );
// };

// export default CourseList;





import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CourseList.css';
import StudentLayout from './StudentLayout';
import Modal from './Modal';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseList = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          const response = await axios.get('https://www.testometer.in:8080/api/v1/user/courses', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setCourses(response.data);
        } else {
          setError('Access token not found');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching course list:', error.message);
        setError(error.message);
      }
    };

    fetchCourseList();
  }, []);

  const filteredCourses = courses.filter(course =>
    course.course_title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearch = event => {
    setSearchQuery(event.target.value);
  };

  const handleSubscribe = async (courseId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        await axios.post(`https://www.testometer.in:8080/api/v1/user/courses/${courseId}/subscribe`, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setModalMessage('Subscription request sent for approval');
        setIsModalOpen(true);
      } else {
        console.error('Access token not found');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setModalMessage('You are already subscribed.');
        setIsModalOpen(true);
      } else {
        console.error('Error subscribing to course:', error.message);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage('');
    navigate('/course-list'); // or any other route if needed
  };

  if (loading) {
    return (
      <div className="user-loader">
        <div className="user-spinner"></div>
      </div>
    );
  }

  return (
    <StudentLayout>
      <div className="student-main-content">
        <h1 className="title">Available Courses</h1>
        {error && <p>Error: {error}</p>}
        <input
          type="text"
          placeholder="Search by course title"
          value={searchQuery}
          onChange={handleSearch}
          className="search-input"
        />
        <div className="course-list-container">
          {filteredCourses.map(course => (
            <div key={course.id} className="course-link">
              <div className="course-box">
                <div className="course-content">
                  <h3 className="course-heading">{course.course_title}</h3>
                  <p className="course-description">{course.title_description}</p>
                </div>
                <button
                  className="subscribe-button"
                  onClick={() => handleSubscribe(course.id)}
                >
                  Subscribe
                </button>
              </div>
            </div>
          ))}
        </div>
        <Modal show={isModalOpen} onClose={closeModal} message={modalMessage} />
      </div>
    </StudentLayout>
  );
};

export default CourseList;






